import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import SelectComponent from '../Select/SelectViewComponent'
import {
    EmbeddedProps,
    EmbeddedType,
    View,
    ViewProps,
} from '../../models/viewModel'
import { SelectModel, SelectType } from '../../models/selectModel'
import { useEffect, useMemo, useState } from 'react'
import { SelectService } from '../../services/SelectService'
import { ViewService } from '../../services/ViewService'
import { OnAddViewResult } from '../Select/SelectTargetComponent'
import TypeInputField from '../Base/TypeInputField/TypeInputField'

interface EmbeddedViewProps {
    view: View
    viewService: ViewService
    flowEditor: unknown
    onChangeView: (view: View) => Promise<void>
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
}

const EmbeddedView = ({
    view,
    flowEditor,
    viewService,
    onChangeView,
    onAddView,
}: EmbeddedViewProps) => {
    const selectService = useMemo(
        () => new SelectService(view.id ?? ''),
        [view.id],
    )
    const [selects, setSelects] = useState<SelectModel[] | undefined>(undefined)

    const [_view, setView] = useState<View>(view)

    const [viewProps, setViewProps] = useState<ViewProps | undefined>(
        _view.parameters,
    )
    const [embeddedProps, setEmbeddedProps] = useState<EmbeddedProps>(
        viewProps?.embeddedProps ?? {
            embedType: EmbeddedType.EMBED,
            url: 'https://google.de',
        },
    )
    const [embedType, setEmbedType] = useState<EmbeddedType>(
        viewProps?.embeddedProps?.embedType ?? 0,
    )

    const updateEmbeddedProps = async (embeddedProps: EmbeddedProps) => {
        setEmbeddedProps(embeddedProps)
        setEmbedType(embeddedProps.embedType)
        const updatedViewProps = { ...viewProps, embeddedProps: embeddedProps }
        setViewProps(updatedViewProps)
        const updatedView: View = {
            ..._view,
            parameters: updatedViewProps,
        }
        if (_view.id !== undefined) {
            setView(updatedView)
            await onChangeView(updatedView)
        }
    }

    const OnBlurURL = async (urlValue: string) => {
        const updatedProps: EmbeddedProps = {
            ...(embeddedProps ?? { embedType: EmbeddedType.EMBED, url: '' }),
            url: urlValue,
        }
        if (view.id !== undefined) {
            updateEmbeddedProps(updatedProps)
        }
    }

    const AddSelect = async () => {
        await selectService.createSelect({
            title: '',
            type: SelectType.View,
            view: _view.id ?? '',
            response: {},
            target: 'undefined',
        })
        setSelects(await selectService.getAll())
    }

    useEffect(() => {
        setView(view)
    }, [view])

    useEffect(() => {
        const fetchSelects = async () => {
            try {
                const fetchedSelects = await selectService.getAll()
                setSelects(fetchedSelects)
                await new Promise(resolve => setTimeout(resolve, 4000))
            } catch (error) {
                console.error('Error fetching flows:', error)
                // Handle error appropriately
            }
        }
        if (selects === undefined) {
            fetchSelects()
        }
    }, [selectService, selects]) // Dependency array ensures the effect runs only when userId changes

    const OnDeleteSelect = async (index: string | undefined) => {
        if (index !== undefined) {
            await selectService.deleteSelect(index)
            setSelects(await selectService.getAll())
        }
    }

    const OnAddViewEvent = (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        if (onAddView !== undefined) {
            return onAddView(viewId, title)
        }
        //TODO; Always return something
        return Promise.resolve({
            _views: [],
            id: '',
        })
    }

    const OnSelectDisplayType = (
        event: SelectChangeEvent<EmbeddedType>,
    ): void => {
        if (_view.id === undefined) {
            return
        }
        const embType = event.target.value as EmbeddedType
        setEmbedType(embType)
        const updatedProps: EmbeddedProps = {
            ...(embeddedProps ?? {
                embedType: EmbeddedType.EMBED,
                Url: 'https://google.de',
            }),
            embedType: embType,
        }
        updateEmbeddedProps(updatedProps)
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography>URL</Typography>
                    <TypeInputField
                        key={'view_url_' + view.id}
                        text={embeddedProps.url}
                        onBlur={OnBlurURL}
                    ></TypeInputField>
                    <Select onChange={OnSelectDisplayType} value={embedType}>
                        <MenuItem value={EmbeddedType.EMBED}>Embed</MenuItem>
                        <MenuItem value={EmbeddedType.EXTERNAL}>
                            Open Externally
                        </MenuItem>
                        <MenuItem value={EmbeddedType.REDIRECT}>
                            Redirect
                        </MenuItem>
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ height: 3 }}>Text</TableCell>
                                <TableCell sx={{ height: 3 }}>
                                    Target
                                    <Button onClick={() => AddSelect()}>
                                        +
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selects &&
                                selects.map(entry => {
                                    return (
                                        <SelectComponent
                                            editor={flowEditor}
                                            key={entry.id}
                                            _select={entry}
                                            onDelete={() => {
                                                OnDeleteSelect(entry.id)
                                            }}
                                            onAddView={OnAddViewEvent}
                                            viewService={viewService}
                                            selectService={selectService}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    )
}

export default EmbeddedView
