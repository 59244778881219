import { useEffect, useState } from 'react'
import { View, ViewAndDimensions } from '../../models/viewModel'
import React from 'react'
import { Flow } from '../../models/flowModel'
import ViewComponent from '../View/ViewComponent'
import { OnAddViewResult } from '../Select/SelectTargetComponent'
import { ViewType } from '../../models/viewType'
import { v4 as uuidv4 } from 'uuid'
import { ViewDimensionsService } from '../../services/ViewDimensionsService'
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble'
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble'
import { Button } from '@mui/material'

export interface FlowEditorAreaProps {
    title?: string
    flow?: Flow
    onFirstView: (id: string) => void
    viewDimensionsService: ViewDimensionsService
}

const FlowEditorArea: React.FC<FlowEditorAreaProps> = ({
    flow,
    onFirstView,
    viewDimensionsService,
}) => {
    const [viewWithDimensions, setViewWithDimensions] = useState<
        ViewAndDimensions[]
    >([])
    const [rootView, setRootView] = useState<View | undefined>()
    const [allFolded, setAllFolded] = useState<boolean>(false)

    const deleteView = async (view: View) => {
        if (view.id === undefined) {
            return
        }
        if (view.id === rootView?.id) {
            //The root element cannot be deleted currently
            return
        }

        const updateViewsWithDimensions =
            await viewDimensionsService.DeleteView(view.id)
        setViewWithDimensions(updateViewsWithDimensions)
    }

    useEffect(() => {
        // Fetch flow views
        let isRunning = false
        const fetchFlowViews = async (
            flow: Flow | undefined,
            viewWithDimensions: ViewAndDimensions[],
        ) => {
            if (
                isRunning === false &&
                flow !== undefined &&
                viewWithDimensions.length === 0
            ) {
                isRunning = true
                const fetchedViewsWithDimensions =
                    await viewDimensionsService.LoadViews()
                for (const entry of fetchedViewsWithDimensions) {
                    const index = entry.view?.externalId ?? 0
                    if (index === 0) {
                        onFirstView(entry.view?.id ?? '')
                    }
                }
                if (fetchedViewsWithDimensions.length === 0) {
                    isRunning = false
                    return
                }
                setViewWithDimensions(fetchedViewsWithDimensions)
                if (flow.firstView !== undefined) {
                    const root = viewDimensionsService.FindView(
                        flow.firstView,
                    )?.view
                    if (root) {
                        setRootView(root)
                    }
                }
                await new Promise(resolve => setTimeout(resolve, 4000))
                isRunning = false
            }
        }
        fetchFlowViews(flow, viewWithDimensions)
    }) // Dependency array

    const handleDimensions = (id: string, width: number, height: number) => {
        const updatedViewWithDimensions = viewDimensionsService.UpdatePosition(
            id,
            width,
            height,
        )
        setViewWithDimensions(updatedViewWithDimensions)
    }

    const handleViewChange = (view: View) => {
        if (view.id === undefined) {
            return
        }
        viewDimensionsService.UpdateView(view.id ?? '', view)
    }

    const OnAddViewEvent = async (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        const id = uuidv4()
        const result = viewDimensionsService.AddView(viewId, {
            id: id,
            externalId: -1,
            title: title,
            type: ViewType.View,
            parameters: {},
            style: {
                folded: false,
            },
            flow: flow?.id ?? '',
        })

        setViewWithDimensions(await result)
        return {
            id: id,
            _views: viewDimensionsService.GetAllViews(),
        }
    }

    function unfoldAll() {
        setAllFolded(false)
    }

    function foldAll() {
        setAllFolded(true)
    }

    return (
        <>
            <div className='flowEditorArea'>
                {viewWithDimensions.map(viewWithDimension => {
                    const view = viewWithDimension.view
                    return (
                        <ViewComponent
                            key={view?.id}
                            view={view ?? {}}
                            x={viewWithDimension.x ?? 200}
                            y={viewWithDimension.y ?? 600}
                            onDimensions={handleDimensions}
                            onChange={handleViewChange}
                            onDelete={deleteView}
                            onAddView={OnAddViewEvent}
                            elkey={'view_' + view?.id}
                            id={view?.id ?? ''}
                            flowEditorArea={this}
                            viewService={viewDimensionsService.GetViewService()}
                            folded={allFolded}
                        />
                    )
                })}
            </div>
            <div className='buttonContainer'>
                <Button
                    className='actionButton'
                    onClick={() => {
                        unfoldAll()
                    }}
                >
                    <UnfoldMoreDoubleIcon></UnfoldMoreDoubleIcon>
                </Button>
                <Button
                    className='actionButton'
                    onClick={() => {
                        foldAll()
                    }}
                >
                    <UnfoldLessDoubleIcon></UnfoldLessDoubleIcon>
                </Button>
            </div>
        </>
    )
}

export default FlowEditorArea
