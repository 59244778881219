export enum SecretPermission {
    HTTP_HEADER = 1,
    HTTP_URL = 2,
    HTTP_BODY = 4,
    VIEW = 8,
}
export interface SecretModel {
    id: string
    name: string
    value?: string
    permissions: number
    encrypted: boolean
}
