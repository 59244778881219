import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Input,
    Typography,
} from '@mui/material'
import { Flow } from '../../models/flowModel'
import { ChangeEvent, useState } from 'react'
import { FlowService } from '../../services/FlowService'
import TypeTextAreaField from '../Base/TypeTextAreaField/TypeTextAreaField'

export interface FlowSettingsAreaProps {
    flow: Flow
}

const FlowSettingsArea = ({ flow }: FlowSettingsAreaProps) => {
    const [isPublic, setIsPublic] = useState<boolean>(
        flow.parameters?.isPublic ?? false,
    )
    const flowService = new FlowService()
    const [apiKey, setAPIKey] = useState<string>(
        flow.parameters?.flowApiKey ?? '',
    )
    const [logoURL, setLogoURL] = useState<string>(flow.style?.logo ?? '')
    const [description, setDescription] = useState<string>(
        flow.description ?? '',
    )

    function onChangePublic(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void {
        setIsPublic(checked)
        flow.parameters.isPublic = checked
        flowService.updateFlow(flow?.id ?? '', flow)
    }

    function onBlurAPIKey(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void {
        setAPIKey(event.target.value)
        flow.parameters.flowApiKey = event.target.value
        flowService.updateFlow(flow?.id ?? '', flow)
    }

    function onChangeAPIKey(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void {
        setAPIKey(event.target.value)
    }

    function onChangeLogoURL(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void {
        setLogoURL(event.target.value)
    }

    function onBlurLogoURL(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void {
        const _logo = event.target.value
        setLogoURL(_logo)
        if (!flow.style) {
            flow.style = {
                logo: _logo,
            }
        } else {
            flow.style.logo = _logo
        }
        flowService.updateFlow(flow?.id ?? '', flow)
    }

    function onBlurDescription(text: string) {
        setDescription(text)
        if (flow !== undefined) {
            flow.description = text
            flowService.updateFlow(flow?.id ?? '', flow)
        }
    }

    return (
        <div>
            <Typography variant='h4'>Settings</Typography>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isPublic}
                            onChange={onChangePublic}
                        />
                    }
                    label='Publicly Available'
                />
                <FormControlLabel
                    control={
                        <Input
                            value={apiKey}
                            onChange={onChangeAPIKey}
                            onBlur={onBlurAPIKey}
                        />
                    }
                    label='API Key'
                />
                <FormControlLabel
                    control={
                        <Input
                            value={logoURL}
                            onChange={onChangeLogoURL}
                            onBlur={onBlurLogoURL}
                        />
                    }
                    label='Logo'
                />
                <FormControlLabel
                    control={
                        <TypeTextAreaField
                            value={description}
                            onBlur={onBlurDescription}
                            _key={'settingsDescriptionArea'}
                        />
                    }
                    label='Description'
                />
            </FormGroup>
        </div>
    )
}

export default FlowSettingsArea
