export class JSONCleaner {
    public static CleanAndStringify(obj: unknown): string {
        // Function to recursively remove null and undefined values
        function removeNullAndUndefined(value: unknown): unknown {
            if (value === null || value === undefined) {
                return undefined
            }
            if (Array.isArray(value)) {
                return value
                    .map(removeNullAndUndefined)
                    .filter(item => item !== undefined)
            }
            if (typeof value === 'object') {
                const cleanedObject: { [key: string]: unknown } = {}
                Object.keys(value).forEach(key => {
                    const cleanedValue = removeNullAndUndefined(
                        (value as Record<string, unknown>)[key],
                    )
                    if (cleanedValue !== undefined) {
                        cleanedObject[key] = cleanedValue
                    }
                })
                return cleanedObject
            }
            return value
        }

        const cleanedObj = removeNullAndUndefined(obj)
        // Stringify the cleaned object with formatting (2 spaces for indentation)
        return JSON.stringify(cleanedObj, null, 2)
    }
}
