import { useEffect, useState } from 'react'
import { SecretModel, SecretPermission } from '../../models/secretModel'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { SecretsService } from '../../services/SecretsService'
import { User } from '../../models/userModel'
import AuthService from '../../services/AuthService'
import { v4 as uuidv4 } from 'uuid'
import { GridAddIcon, GridDeleteForeverIcon } from '@mui/x-data-grid'

const SecretsComponent = () => {
    const [secrets, setSecrets] = useState<SecretModel[]>([])
    const [secretToAdd, setSecretToAdd] = useState<SecretModel>({
        id: uuidv4(),
        encrypted: false,
        name: '',
        permissions: 15, //TODO Optimize this
    })
    const [user, setUser] = useState<User>()
    const [secretToAddName, setSecretToAddName] = useState<string>('')
    const [secretToAddValue, setSecretToAddValue] = useState<string>('')
    const [secretToAddEncrypted, setSecretToAddEncrypted] =
        useState<boolean>(false)
    const [secretToAddPermissions, setSecretToAddPermissions] =
        useState<number>(0)

    useEffect(() => {
        const loadUser = async () => {
            if (user === undefined) {
                const secretsService = new SecretsService()
                const authService = AuthService.getInstance()
                const ourUser = await authService?.getMe()
                if (ourUser) {
                    setUser(ourUser)
                    const ourSecrets = await secretsService.getAllByUser(
                        ourUser.id ?? '',
                    )
                    setSecrets(ourSecrets ?? [])
                }
            }
        }
        if (user === undefined) {
            loadUser()
        }
    })

    function onChangeSecretName(): void {
        const secretUpdate = secretToAdd
        secretUpdate.name = secretToAddName
        setSecretToAdd(secretUpdate)
    }

    function onChangeSecretValue(): void {
        const secretUpdate = secretToAdd
        secretUpdate.value = secretToAddValue
        setSecretToAdd(secretUpdate)
    }

    function onChangeEncryption(): void {
        setSecretToAddEncrypted(!secretToAddEncrypted)
    }

    function onChangeSetPermission(
        _: React.ChangeEvent<HTMLInputElement>,
        permission: SecretPermission,
    ): void {
        let permissions = secretToAddPermissions
        if ((permissions & permission) === 0) {
            permissions += permission
        } else {
            permissions -= permission
        }
        setSecretToAddPermissions(permissions)
    }

    async function onDeleteSecret(secretId: string | undefined) {
        if (!secretId) {
            return
        }
        const secretsService = new SecretsService()
        await secretsService.deleteSecret(secretId)
        const ourSecrets = await secretsService.getAllByUser(user?.id ?? '')
        setSecrets(ourSecrets ?? [])
    }

    async function onAddSecret(): Promise<void> {
        secretToAdd.permissions = secretToAddPermissions
        secretToAdd.encrypted = secretToAddEncrypted
        secretToAdd.name = secretToAddName
        secretToAdd.value = secretToAddValue

        const secretsService = new SecretsService()
        await secretsService.addSecret(secretToAdd)
        setSecretToAdd({
            id: uuidv4(),
            encrypted: false,
            name: '',
            permissions: 15, //TODO Optimize this
        })
        setSecretToAddEncrypted(true)
        setSecretToAddName('')
        setSecretToAddPermissions(15)
        setSecretToAddValue('')
        if (user) {
            setSecrets((await secretsService.getAllByUser(user.id ?? '')) ?? [])
        }
    }

    return (
        <div>
            <h1>Keys</h1>
            <h2>Your storage for Secrets, Ids and Fixed values</h2>
            <p>
                The key storage is used to store data that is sensitive or hard
                to remember. You can use it to store API keys or URLs that you
                might use more often. <br />
                In your views, you can point to them using $(keys.Name) or
                $(k.Name).
            </p>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Encrypted</TableCell>
                        <TableCell>Use in View</TableCell>
                        <TableCell>Use in Body</TableCell>
                        <TableCell>Use in Header</TableCell>
                        <TableCell>Use in URL</TableCell>
                        <TableCell> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {secrets &&
                        secrets.map(entry => {
                            return (
                                <TableRow>
                                    <TableCell>{entry.name}</TableCell>
                                    <TableCell>
                                        {entry.value ?? '*******'}
                                    </TableCell>
                                    <TableCell>
                                        {entry.encrypted ? 'X' : ''}
                                    </TableCell>

                                    <TableCell>
                                        {(entry.permissions &
                                            SecretPermission.VIEW) !==
                                        0
                                            ? 'X'
                                            : ''}
                                    </TableCell>
                                    <TableCell>
                                        {(entry.permissions &
                                            SecretPermission.HTTP_BODY) !==
                                        0
                                            ? 'X'
                                            : ''}
                                    </TableCell>
                                    <TableCell>
                                        {(entry.permissions &
                                            SecretPermission.HTTP_HEADER) !==
                                        0
                                            ? 'X'
                                            : ''}
                                    </TableCell>
                                    <TableCell>
                                        {(entry.permissions &
                                            SecretPermission.HTTP_URL) !==
                                        0
                                            ? 'X'
                                            : ''}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() =>
                                                onDeleteSecret(entry.id)
                                            }
                                        >
                                            <GridDeleteForeverIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    <TableRow>
                        <TableCell>
                            <input
                                value={secretToAddName}
                                onChange={e =>
                                    setSecretToAddName(e.target.value)
                                }
                                onBlur={onChangeSecretName}
                            />
                        </TableCell>
                        <TableCell>
                            <input
                                value={secretToAddValue}
                                onChange={e =>
                                    setSecretToAddValue(e.target.value)
                                }
                                onBlur={onChangeSecretValue}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={secretToAddEncrypted}
                                onChange={onChangeEncryption}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={
                                    (secretToAddPermissions &
                                        SecretPermission.VIEW) !==
                                    0
                                }
                                onChange={e => {
                                    onChangeSetPermission(
                                        e,
                                        SecretPermission.VIEW,
                                    )
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={
                                    (secretToAddPermissions &
                                        SecretPermission.HTTP_BODY) !==
                                    0
                                }
                                onChange={e => {
                                    onChangeSetPermission(
                                        e,
                                        SecretPermission.HTTP_BODY,
                                    )
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={
                                    (secretToAddPermissions &
                                        SecretPermission.HTTP_HEADER) !==
                                    0
                                }
                                onChange={e => {
                                    onChangeSetPermission(
                                        e,
                                        SecretPermission.HTTP_HEADER,
                                    )
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={
                                    (secretToAddPermissions &
                                        SecretPermission.HTTP_URL) !==
                                    0
                                }
                                onChange={e => {
                                    onChangeSetPermission(
                                        e,
                                        SecretPermission.HTTP_URL,
                                    )
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </TableCell>
                        <TableCell>
                            <Button onClick={onAddSecret}>
                                <GridAddIcon />
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}

export default SecretsComponent
