import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Home, Login, Menu as MenuIcon } from '@mui/icons-material'
import User from '../User'

const TopBar = () => {
    const onMenuItemClick = () => {
        alert('Geht noch nich!')
    }

    return (
        <AppBar position='static'>
            <Toolbar variant='dense'>
                <IconButton data-url='/' onClick={onMenuItemClick}>
                    <Home fontSize='large' sx={{ color: 'white' }} />
                </IconButton>
                <Box sx={{ flexGrow: 1, display: 'flex', mx: 2 }}>
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' color='inherit' component='div'>
                        ScanGuide
                    </Typography>
                </Box>
                <IconButton color='primary' edge='end'>
                    <Login>Login</Login>
                </IconButton>
                <User />
            </Toolbar>
        </AppBar>
    )
}

export default TopBar
