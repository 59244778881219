import { createSlice } from '@reduxjs/toolkit'
import { KeycloakProfile } from 'keycloak-js'
import AuthService from '../services/AuthService'
import authConfig from '../config/auth.config'
import { AppDispatch, RootState } from './store'

export interface AuthState {
    success: boolean
    profile: KeycloakProfile
}

const initialState: AuthState = {
    success: false,
    profile: {},
}

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        init: state => {
            state.success = false
        },
        initSuccess: (state, action) => {
            state.success = action.payload
        },
        initFailure: state => {
            state.success = false
        },
        setUser: (state, action) => {
            state.profile = action.payload
        },
    },
})

// Actions
export const { init } = slice.actions

const auth: AuthService = new AuthService(authConfig)
console.log('AuthConfig: ' + JSON.stringify(authConfig))
export const startInit = async (dispatch: AppDispatch) => {
    try {
        dispatch(slice.actions.init())

        const success = await auth?.initialize({})

        if (success) {
            const profile = await auth.profile
            dispatch(slice.actions.initSuccess({ success }))
            dispatch(slice.actions.setUser(profile))
        } else {
            dispatch(slice.actions.initFailure())
        }
    } catch (ex) {
        dispatch(slice.actions.initFailure())
    }
}

// Selectors
export const isInitializedSelector = (state: RootState) => state.auth.success
export const userProfileSelector = (state: RootState) => state.auth.profile

export default slice.reducer
