import APIService from './APIService'
import { View, ViewOrRedirect } from '../models/viewModel' // Adjust the import path as necessary

export class ViewService {
    private flowId: string
    constructor(_flowId: string) {
        this.flowId = _flowId
    }

    public async getAll(): Promise<View[] | undefined> {
        try {
            if (this.flowId === undefined || this.flowId === '') {
                return []
            }
            return await APIService.jsonRequest<View[]>(
                '/views/flow/' + this.flowId,
                'GET',
            )
        } catch (exception) {
            console.log('Exception: ' + exception)
            return []
        }
    }

    public async executeViewById(
        id: string,
        dataId: string | null = null,
        sessionId: string | null,
    ): Promise<ViewOrRedirect | undefined> {
        if (dataId !== null && dataId !== undefined) {
            if (sessionId !== null && sessionId !== undefined) {
                return await APIService.jsonRequest(
                    `/views/execute/${id}?dataId=${dataId}&sessionId=${sessionId}`,
                    'GET',
                )
            } else {
                return await APIService.jsonRequest(
                    `/views/execute/${id}?dataId=${dataId}`,
                    'GET',
                )
            }
        } else {
            if (sessionId !== null && sessionId !== undefined) {
                return await APIService.jsonRequest(
                    `/views/execute/${id}?sessionId=${sessionId}`,
                    'GET',
                )
            } else {
                return await APIService.jsonRequest(
                    `/views/execute/${id}`,
                    'GET',
                )
            }
        }
    }

    public async getViewById(
        id: string,
        data: string | null = null,
        sessionId: string | null,
    ): Promise<View | undefined> {
        if (data !== null && data !== undefined) {
            if (sessionId !== null && sessionId !== undefined) {
                return APIService.jsonRequest(
                    `/views/${id}?data=${data}&sessionId=${sessionId}`,
                    'GET',
                )
            } else {
                return APIService.jsonRequest(
                    `/views/${id}?data=${data}`,
                    'GET',
                )
            }
        } else {
            if (sessionId !== null && sessionId !== undefined) {
                return APIService.jsonRequest(
                    `/views/${id}?sessionId=${sessionId}`,
                    'GET',
                )
            } else {
                return APIService.jsonRequest(`/views/${id}`, 'GET')
            }
        }
    }

    public async createView(viewData: View): Promise<View | undefined> {
        return APIService.jsonRequest<View>('/views', 'POST', viewData)
    }

    public async updateView(
        _: string,
        viewData: View,
    ): Promise<View | undefined> {
        return APIService.jsonRequest(`/views`, 'PUT', viewData)
    }

    public async deleteView(id: string): Promise<void> {
        return APIService.jsonRequest(`/views/${id}`, 'DELETE', null, false)
    }
}
