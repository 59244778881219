import { ImageList, ImageListItem, IconButton, Box } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'
import { useEffect, useState } from 'react'
import { getUserById } from '../../services/UserService'
import { MediaModel } from '../../models/mediaModel'
import { MediaService } from '../../services/MediaService'
import { User } from '../../models/userModel'
import AuthService from '../../services/AuthService'
import FileUploadDialogButton from '../Alert/FileUploadDialogButton'
import { v4 as uuidv4 } from 'uuid'
import { GridDeleteForeverIcon } from '@mui/x-data-grid'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import fontService from '../../services/FontService'

const MediaComponent = () => {
    const [items, setItems] = useState<MediaModel[]>()
    const [mediaService, setMediaService] = useState<MediaService>()
    const [user, setUser] = useState<User>()
    const authService = AuthService.getInstance()

    useEffect(() => {
        const loadImages = async () => {
            try {
                const _user = await authService?.getMe()
                setUser(_user)
                if (_user !== undefined) {
                    const user: User | undefined = await getUserById(
                        _user.id ?? '',
                    )
                    if (user === undefined) {
                        return
                    }
                    const _mediaService = new MediaService(user)
                    setMediaService(_mediaService)
                    setItems(await _mediaService.getMediaByUser())
                }
            } catch (exception) {
                return setItems([])
            }
        }
        loadImages()
    }, [authService])

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
            //TODO Add some Copy-Effect here!
        })
    }

    function getMediaURL(id: string): string {
        return `${process.env.REACT_APP_CLICKGUIDE_API_URL}/media/${id}`
    }

    async function onUploadFile(name: string, file: File): Promise<void> {
        // Перевіряємо розширення файлу
        const fileExtension = file.name.split('.').pop()?.toLowerCase()
        const fontExtensions = ['ttf', 'otf', 'woff', 'woff2']

        if (fontExtensions.includes(fileExtension ?? '')) {
            console.log('Файл є шрифтом. Виконуємо іншу логіку.')
            const formData = new FormData()
            formData.append('font', file)
            const res = await fontService.createFont(formData)
            console.log(res)
        } else {
            if (mediaService) {
                const _media = {
                    id: uuidv4(),
                    date: new Date(),
                    format: file.type,
                    user: user?.id ?? '',
                    name: name,
                }
                const media = await mediaService.createMedia(_media)
                if (media !== undefined) {
                    const result = await mediaService.uploadFile(media.id, file)
                    if (result !== undefined) {
                        const _mediaList = await mediaService.getMediaByUser()
                        setItems(_mediaList)
                    }
                }
            }
        }
    }

    function onCancelUpload() {
        alert('Upload canceled!')
    }

    async function deleteFile(id: string) {
        if (id != undefined) {
            if (mediaService) {
                await mediaService.deleteMedia(id)
                const data = items?.filter(s => s.id !== id)
                setItems(data)
            }
        }
    }

    function openInNewTab(url: string) {
        if (url !== undefined) {
            window.open(url, '_blank')
        }
    }

    return (
        <div className='mediaComponent'>
            <h1>Media</h1>
            <FileUploadDialogButton
                title='Upload'
                onOK={onUploadFile}
                onCancel={onCancelUpload}
            >
                <>
                    <h2>Upload</h2>
                    <AddIcon />
                </>
            </FileUploadDialogButton>
            <ImageList sx={{ width: '100%' }} variant='woven' cols={5} gap={8}>
                {items &&
                    items.map(entry => (
                        <ImageListItem
                            key={entry.id}
                            style={{
                                minHeight: '400px',
                                maxHeight: '400px',
                                margin: '4px',
                                padding: '4px',
                                border: 'solid',
                                borderWidth: '3px',
                                borderColor: '#000000',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span className='title'>{entry.name}</span>
                            {!entry.format.startsWith('image') && (
                                <PictureAsPdfIcon />
                            )}
                            {entry.format.startsWith('image') && (
                                <img
                                    src={getMediaURL(entry.id)}
                                    alt={entry.name ?? ''}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '340px',
                                        maxHeight: '350px',
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end', // Aligns children (icons) to the bottom
                                    padding: '4px',
                                }}
                            >
                                <IconButton
                                    className='copyIcon'
                                    onClick={() =>
                                        copyToClipboard(getMediaURL(entry.id))
                                    }
                                    size='small'
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        openInNewTab(getMediaURL(entry.id))
                                    }
                                >
                                    <OpenInNewIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => deleteFile(entry.id)}
                                    size='small'
                                >
                                    <GridDeleteForeverIcon />
                                </IconButton>
                            </Box>
                        </ImageListItem>
                    ))}
                <ImageListItem
                    style={{
                        height: '400px',
                        margin: '4px',
                        padding: '4px',
                        border: 'solid',
                        borderWidth: '3px',
                        borderColor: '#000000',
                    }}
                >
                    <span className='title'>ScanGuide Logo</span>
                    <img
                        src='https://app.my-clickguide.de/ScanGuide_Logo.png'
                        alt='ScanGuide Logo'
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '340px',
                        }}
                    />
                    <Box sx={{ display: 'flex', alignContent: 'baseline' }}>
                        <IconButton
                            className='copyIcon'
                            onClick={() =>
                                copyToClipboard(
                                    'https://app.my-clickguide.de/ScanGuide_Logo.png',
                                )
                            }
                            size='small'
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </ImageListItem>
            </ImageList>
        </div>
    )
}
export default MediaComponent
