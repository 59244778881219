import APIService from './APIService'
import { MediaModel } from '../models/mediaModel'
import { User } from '../models/userModel'

export class MediaService {
    constructor(private user: User) {}

    public async getMediaByUser(): Promise<MediaModel[] | undefined> {
        try {
            const userId = this.user.id
            return await APIService.jsonRequest(`/media/user/${userId}`, 'GET')
        } catch (exception) {
            console.error('Failed to get media by user:', exception)
            return []
        }
    }

    public async getMediaInfoById(id: string): Promise<MediaModel | undefined> {
        try {
            return await APIService.jsonRequest(`/media/info/${id}`, 'GET')
        } catch (exception) {
            console.error('Failed to get media info by ID:', exception)
            throw new Error('Failed to retrieve media info.')
        }
    }

    public async getMediaById(id: string): Promise<Blob | undefined> {
        try {
            return await APIService.jsonRequest(`/media/${id}`, 'GET')
        } catch (exception) {
            console.error('Failed to get media by ID:', exception)
            return undefined
        }
    }

    public async createMedia(
        media: MediaModel,
    ): Promise<MediaModel | undefined> {
        try {
            return await APIService.jsonRequest(`/media/info`, 'POST', media)
        } catch (exception) {
            console.error('Failed to create media entry:', exception)
            return undefined
        }
    }

    public async uploadFile(
        id: string,
        file: File,
    ): Promise<MediaModel | undefined> {
        try {
            const formData = new FormData()
            formData.append('file', file)
            return APIService.binRequest(
                `/media/upload?id=${id}`,
                'POST',
                formData,
            )
        } catch (exception) {
            console.error('Failed to upload file:', exception)
            return undefined
        }
    }

    public async deleteMedia(id: string): Promise<string | undefined> {
        try {
            return await APIService.jsonRequest(`/media/${id}`, 'DELETE')
        } catch (exception) {
            console.error('Failed to upload file:', exception)
            return undefined
        }
    }
}
