import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    TableCell,
    TableRow,
} from '@mui/material'
import {
    HttpSelectProps,
    SelectDefaults,
    SelectModel,
} from '../../models/selectModel'
import { OnAddViewResult } from './SelectTargetComponent'
import { useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { View } from '../../models/viewModel'
import { SelectService } from '../../services/SelectService'
import { ViewService } from '../../services/ViewService'
import SelectTargetComponent from './SelectTargetComponent'

export interface SelectComponentProps {
    _select: SelectModel
    editor: unknown
    onDelete: (id: string) => void
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
    viewService: ViewService
    selectService: SelectService
}

const SelectHttpComponent = ({
    _select,
    onDelete,
    onAddView,
    viewService,
    selectService,
}: SelectComponentProps) => {
    const [views, setViews] = useState<View[]>([])
    if (_select.response?.httpProps === undefined) {
        _select.response.httpProps = SelectDefaults.DefaultHttpProps
    }
    const [select, setSelect] = useState<SelectModel>(_select)
    const [httpProps, setHTTPProps] = useState<HttpSelectProps>(
        select.response?.httpProps ?? SelectDefaults.DefaultHttpProps,
    )

    useEffect(() => {
        if (views.length === 0) {
            const loadViews = async () => {
                const updatedViews = await viewService.getAll()
                setViews(updatedViews ?? [])
            }
            loadViews()
        }
    }, [views, viewService])

    function Delete() {
        if (select.id !== undefined) {
            onDelete(select.id)
        }
    }

    function onAddViewEvent(
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> {
        return onAddView(viewId, title)
    }

    function onSelectViewEvent(viewId: string) {
        if (select.id !== undefined) {
            const updatedSelect: SelectModel = {
                ...select,
                target: viewId,
            }
            setSelect(updatedSelect)
            selectService.updateSelect(updatedSelect.id ?? '', updatedSelect)
        } else {
            console.log('ERROR: Select has no ID!')
        }
    }

    // Function to handle the change event
    const handleChange = async (event: SelectChangeEvent<string>) => {
        // Get the selected value from the event object
        const newValue = event.target.value
        const updateProps = { ...httpProps, code: newValue }
        setHTTPProps(updateProps)
        const updateSelect = { ...select, response: { httpProps: updateProps } }
        setSelect(updateSelect)
        await selectService.updateSelect(select.id ?? '', updateSelect)
    }

    return (
        <>
            <TableRow key={select.id}>
                <TableCell sx={{ height: '20px', width: '40%' }}>
                    <Select value={httpProps.code} onChange={handleChange}>
                        <MenuItem value='***'>Default</MenuItem>
                        <MenuItem value='200'>200 - OK</MenuItem>
                        <MenuItem value='201'>201 - Created</MenuItem>
                        <MenuItem value='2**'>2** - Other OK</MenuItem>
                        <MenuItem value='400'>400 - Bad Request</MenuItem>
                        <MenuItem value='401'>401 - UnAuthorized</MenuItem>
                        <MenuItem value='404'>404 - Not found</MenuItem>
                        <MenuItem value='4**'>4** - Other Bad Request</MenuItem>
                        <MenuItem value='5**'>5** - ServerError</MenuItem>
                    </Select>
                </TableCell>
                <TableCell sx={{ height: '20px', width: '40%' }}>
                    <SelectTargetComponent
                        select={select}
                        viewService={viewService}
                        onAddViewCallback={onAddViewEvent}
                        onSelect={onSelectViewEvent}
                    />
                </TableCell>
                <TableCell sx={{ height: '20px', width: '20%' }}>
                    <Button
                        className='DeleteSelectButton'
                        onClick={() => {
                            Delete()
                        }}
                    >
                        <DeleteForeverIcon />
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SelectHttpComponent
