import { View, ViewAndDimensions } from '../models/viewModel'
import { ViewTree } from '../utils/ViewTree'
import { ViewService } from './ViewService'

export class ViewDimensionsService {
    private dimensions: ViewAndDimensions[] | undefined

    constructor(
        public viewService: ViewService,
        public flowId: string,
    ) {
        this.dimensions = []
    }

    public DeleteView(viewId: string): Promise<ViewAndDimensions[]> {
        this.viewService.deleteView(viewId)
        this.dimensions = this.dimensions?.filter(
            entry => entry.view?.id !== viewId,
        )
        return Promise.resolve(this.dimensions ?? [])
    }

    public async UpdateView(viewId: string, view: View) {
        const result = await this.viewService.updateView(viewId, view)
        const toUpdate = this.dimensions?.find(
            entry => entry.view?.id === viewId,
        )
        if (toUpdate) {
            toUpdate.view = result
        }
    }

    public async AddView(
        parentId: string,
        view: View,
    ): Promise<ViewAndDimensions[]> {
        const parentView = this.dimensions?.filter(
            entry => entry.view?.id === parentId,
        )
        if (!parentView || parentView.length !== 1) {
            console.log(
                'ERROR Adding new View. ParentView ' +
                    parentId +
                    'was not found; canceling add!',
            )
            return Promise.resolve(this.dimensions ?? [])
        }
        let minId = parentView[0].view?.externalId ?? 0 * 10
        if (minId === 0) {
            minId = 10
        } else {
            minId = minId * 10
        }
        let nextId = minId
        let errorHappened = false
        this.dimensions?.forEach(entry => {
            if (
                entry.view?.externalId !== undefined &&
                entry.view.externalId >= nextId &&
                entry.view.externalId < minId + 9
            ) {
                nextId = entry.view.externalId + 1
            } else if (entry.view?.externalId === minId + 9) {
                //In this case, we already have 10 entries. We can't add more
                errorHappened = true
            }
        })

        if (errorHappened) {
            console.log('There are already 9 fields; cannot add number 10!')
            return Promise.resolve(this.dimensions ?? [])
        }
        view.externalId = nextId

        const updatedView = await this.viewService.createView(view)
        this.dimensions?.push(
            new ViewAndDimensions({
                view: updatedView,
                height: 800,
                width: 500,
            }),
        )
        return Promise.resolve(this.dimensions ?? [])
    }

    public async LoadViews(): Promise<ViewAndDimensions[]> {
        if (this.dimensions?.length === 0) {
            const views = await this.viewService.getAll()
            views?.forEach(element => {
                const compareView = this.dimensions?.filter(
                    entry => entry.view?.id === element.id,
                )
                if (compareView?.length === 1) {
                    compareView[0].view = element
                } else if (compareView?.length === 0) {
                    this.dimensions?.push(
                        new ViewAndDimensions({
                            view: element,
                            height: 800,
                            width: 500,
                        }),
                    )
                } else {
                    console.log(
                        "We found the same view more than once; something's deadly wrong here",
                    )
                }
            })
        }
        return this.dimensions ?? []
    }

    public GetAllViews(): View[] {
        return this.dimensions?.map(entry => entry.view ?? {}) ?? []
    }

    public UpdatePosition(
        id: string,
        width: number,
        height: number,
    ): ViewAndDimensions[] {
        const updatedViewWithDimension = this.dimensions?.filter(
            entry => entry.view?.id === id,
        )
        if (updatedViewWithDimension?.length === 1) {
            updatedViewWithDimension[0].width = width
            updatedViewWithDimension[0].height = height
        }

        const unknownDimensions = this.dimensions?.filter(entry => {
            if (entry.width !== undefined && entry.width > 0) {
                if (entry.height !== undefined && entry.height > 0) {
                    return false
                }
            }
            return true
        })
        if (!unknownDimensions) {
            return this.dimensions ?? []
        }
        const rootView = this.FindRootView()
        if (unknownDimensions.length === 0 && rootView !== undefined) {
            const tree = new ViewTree(0, rootView, this.dimensions ?? [])
            tree.setX(0)
            tree.setY(50, 0)
            //tree.DebugTree("");
            this.dimensions = tree.getAllViews()
        }
        return this.dimensions ?? []
    }

    public FindRootView(): ViewAndDimensions | undefined {
        if (!this.dimensions) {
            return undefined
        }
        const results = this.dimensions.filter(
            entry => entry.view?.externalId === 0,
        )
        if (!results || results.length !== 1) {
            return undefined
        }
        return results[0]
    }

    public FindView(id: string): ViewAndDimensions | undefined {
        return this.dimensions?.filter(entry => entry.view?.id === id)?.[0]
    }

    public GetViewService(): ViewService {
        return this.viewService
    }
}
