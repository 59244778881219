export class CustomerAddressDataModel {
    name = ''
    street = ''
    number = '1'
    city = ''
    country = ''
    zipCode = ''
}

export class CustomerContactDataModel {
    mainPhoneNumber = ''
    email = ''
}

export class CustomerIdentityDataModel {
    mainFont = 'Arial'
    backgroundColor = '#FFFFFF'
    buttonColor = '#BBBBDD'
    buttonFontColor = '#007700'
    fontColor = '#000000'
    secondaryColor = '#000000'
    logoURL = ''
}

export class CustomerDataModel {
    addressData?: CustomerAddressDataModel = new CustomerAddressDataModel()
    contactData?: CustomerContactDataModel = new CustomerContactDataModel()
    identityData?: CustomerIdentityDataModel = new CustomerIdentityDataModel()
    privacyURL?: string = ''
    domainURL?: string = ''
}
