import APIService from './APIService'
import { User } from '../models/userModel'
import { CustomerIdentityDataModel } from '../models/customerData'

export const getUsers = async (): Promise<User[] | undefined> => {
    return APIService.jsonRequest('/users', 'GET')
}

export const getUserById = async (id: string): Promise<User | undefined> => {
    return APIService.jsonRequest(`/users/${id}`, 'GET')
}

export const getStyleByUserId = async (
    id: string,
): Promise<CustomerIdentityDataModel | undefined> => {
    return APIService.jsonRequest(`/users/style/${id}`, 'GET')
}

export const createUser = async (userData: User): Promise<User | undefined> => {
    return APIService.jsonRequest('/users', 'POST', userData)
}

export const updateUser = async (
    id: string,
    userData: User,
): Promise<User | undefined> => {
    return APIService.jsonRequest(`/users`, 'PUT', userData)
}

export const deleteUser = async (id: string): Promise<void> => {
    return APIService.jsonRequest(`/users/${id}`, 'DELETE', null, false)
}
