import { ViewAndDimensions } from '../models/viewModel'

export class ViewTree {
    public kids: ViewTree[] = []
    constructor(
        public layer: number,
        public rootView: ViewAndDimensions,
        public allViews: ViewAndDimensions[],
    ) {
        const extId: string =
            rootView.view?.externalId === 0
                ? '1'
                : rootView.view?.externalId?.toString() ?? '0'
        const filteredList = allViews.filter(entry => {
            const inExtId: string = entry.view?.externalId?.toString() ?? '0'
            if (
                entry.view?.externalId !== rootView.view?.externalId &&
                inExtId.startsWith(extId) &&
                inExtId.length === extId.length + 1
            ) {
                return entry
            } else {
                return null
            }
        })
        filteredList.forEach(entry => {
            const subFilter: string = entry.view?.externalId?.toString() ?? '0'
            this.kids.push(
                new ViewTree(
                    layer + 1,
                    entry,
                    allViews.filter(subEntry => {
                        return (
                            subEntry.view?.externalId !==
                                entry.view?.externalId &&
                            subEntry.view?.externalId
                                ?.toString()
                                .startsWith(subFilter)
                        )
                    }),
                ),
            )
        })
        // console.log("Tree layer:  "+ layer + " ExternalId: " + view.externalId + " TreeKids: " + this.kids.length);
    }

    public getAllViews(): ViewAndDimensions[] {
        let allViews: ViewAndDimensions[] = []
        this.kids.forEach(entry => {
            allViews = [...allViews, ...entry.getAllViews()]
        })
        allViews.push(this.rootView)
        return allViews
    }

    public getWidth(): number {
        return this.rootView.width ?? 0
    }

    public getHeight(): number {
        if (this.kids.length > 0) {
            let height = 0
            this.kids.forEach(kid => {
                height += kid.getHeight() + 80
            })
            return height
        } else {
            return this.rootView.height ?? 0
        }
    }

    public setX(layer: number): number {
        this.rootView.x = layer * 700
        let maxX = this.rootView.x + (this.rootView.width ?? 0)
        this.kids.forEach(entry => {
            const result = entry.setX(layer + 1)
            maxX = maxX > (result ?? 0) ? maxX : result
        })
        const posX = this.rootView.x
        //console.log("Setting X for External Id " + this.view.externalId + " to " + this.view.x);
        return (maxX > posX ? maxX : posX) + (this.rootView.width ?? 0)
    }

    public setY(baseY: number, offsetY: number): number {
        // Calculate the Y position for the current root view
        this.rootView.y = baseY + offsetY

        // Track the total height as we add each child
        let totalHeight = this.rootView.height ?? 0

        // Initialize currentY to track the y position for each child
        let currentY = 0

        // Iterate over each child to set their Y position recursively
        this.kids.forEach(kid => {
            // Set the Y position of the child, starting from currentY
            const kidHeight = kid.setY(this.rootView.y ?? 0, currentY) // Add 80px space between siblings
            currentY += kidHeight + 80 // Update currentY by the height of the kid
            totalHeight = Math.max(totalHeight, currentY) // Update total height of the subtree
        })

        // Return the total height of this subtree including the current root view height
        return totalHeight
    }

    public DebugTree(preText: string) {
        console.log(preText + this.rootView?.view?.externalId)
        preText += '   '
        this.kids.forEach(entry => {
            entry.DebugTree(preText)
        })
    }
}
