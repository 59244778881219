import AuthService, { AuthLocalStorageKeys } from './AuthService'

class APIService {
    private baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    private buildHeader(): Headers {
        const token = localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN)
        if (token !== undefined && token !== null) {
            return new Headers({
                'Content-Type': 'application/json',
                // Include additional headers as needed
                Authorization: `Bearer ${token}`,
            })
        } else {
            return new Headers({
                'Content-Type': 'application/json',
                // Include additional headers as needed
            })
        }
    }

    private async sendRequest(
        endpoint: string,
        config: RequestInit,
    ): Promise<Response | undefined> {
        try {
            let response = await fetch(`${this.baseUrl}${endpoint}`, config)
            if (response.status === 401) {
                await AuthService.getInstance()?.refresh()
                response = await fetch(`${this.baseUrl}${endpoint}`, config)
                if (!response.ok) {
                    // You can further customize error handling here
                    throw new Error(`HTTP error! status: ${response.status}`)
                }
            } else if (!response.ok) {
                // You can further customize error handling here
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            // Assuming JSON response; adjust if needed
            return response
        } catch (ex) {
            console.log('Exception: ' + ex)
        }
    }

    public async jsonRequest<T, D = object>(
        endpoint: string,
        method = 'GET',
        body?: D,
        needsAnswer = true,
    ): Promise<T | undefined> {
        const headers = this.buildHeader()

        const config: RequestInit = {
            method,
            headers,
            body: body ? JSON.stringify(body) : null,
        }
        const result = await this.sendRequest(endpoint, config)
        if (needsAnswer) {
            try {
                const resultString = result?.json()
                return resultString
            } catch (ex) {
                console.log('Exception creating JSON: ' + ex)
                return {} as T
            }
        } else {
            return
        }
    }

    public async binRequest<T = unknown>(
        endpoint: string,
        method = 'GET',
        body?: FormData,
        needsAnswer = true,
    ): Promise<T | undefined> {
        const headers = this.buildHeader()
        headers.delete('Content-Type')

        const config: RequestInit = {
            method,
            headers,
            body: body,
        }
        const result = await this.sendRequest(endpoint, config)
        if (needsAnswer) {
            return result?.json()
        } else {
            return
        }
    }

    public async getBlob(endpoint: string): Promise<Blob | undefined> {
        const headers = this.buildHeader()
        const config: RequestInit = {
            method: 'GET',
            headers,
        }
        const result = await this.sendRequest(endpoint, config)
        if (result) {
            return result.blob()
        } else {
            return
        }
    }
}

// Export a single instance, or you can export the class itself to instantiate elsewhere
const apiService = new APIService(
    process.env.REACT_APP_CLICKGUIDE_API_URL ?? 'http://localhost:3002',
) // Replace with your actual API URL
export default apiService
