import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FlowService } from '../../services/FlowService'
import { Flow } from '../../models/flowModel'
import FlowEditorArea from './FlowEditorArea'
import FlowTitleComponent from './FlowTitleComponent'
import { Button, Link, Tooltip, Typography } from '@mui/material'
import FlowShareArea from './FlowShareArea'
import FlowSettingsArea from './FlowSettingsArea'
import FlowDataArea from './FlowDataArea'
import SchemaIcon from '@mui/icons-material/Schema'
import TocIcon from '@mui/icons-material/Toc'
import SettingsIcon from '@mui/icons-material/Settings'
import ShareIcon from '@mui/icons-material/Share'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import FlowStatisticsArea from './FlowStatisticsArea'
import { ViewService } from '../../services/ViewService'
import { ViewDimensionsService } from '../../services/ViewDimensionsService'
import DownloadIcon from '@mui/icons-material/Download'
import { Download } from '../../utils/DownloadUtil'
import { JSONCleaner } from '../../utils/JSONCleanerUtils'
import FlowSessionArea from './FlowSessionArea'
import PreviewIcon from '@mui/icons-material/Preview'
import { DataService } from '../../services/DataService'

interface FlowComponentProps {
    flowId: string
}

const FlowComponent = ({ flowId }: FlowComponentProps) => {
    const viewService = new ViewService(flowId ?? '')
    const viewDimensionsService = new ViewDimensionsService(
        viewService,
        flowId ?? '',
    )

    const [flow, setFlow] = useState<Flow>({
        name: 'Undefined',
        parameters: { isPublic: false },
    })

    const [searchParams] = useSearchParams()

    const [tab, setTab] = useState<number>(
        Number.parseInt(searchParams.get('tab') ?? '0'),
    )
    const flowService = useMemo(() => new FlowService(), [])
    const [, setFirstView] = useState<string>('')

    const DownloadFlow = async (flowId: string) => {
        const content = await flowService.exportCompleteFlow(flowId)
        Download(flowId + '.json', JSONCleaner.CleanAndStringify(content))
    }

    const fetchFlow = useCallback(async () => {
        try {
            const fetchedFlow = await flowService.getFlowById(flowId ?? '')
            if (fetchedFlow) setFlow(fetchedFlow)
        } catch (error) {
            console.error('Error fetching flows:', error)
            // Handle error appropriately
        }
    }, [flowService])

    const onTitleChangeEvent = async (text: string) => {
        if (flow.id !== undefined && flowService !== undefined) {
            const updatedFlow = {
                ...flow,
                name: text,
            }
            flowService.updateFlow(flow?.id ?? '', updatedFlow)
            setFlow(updatedFlow)
        }
    }

    const [isDataExist, setIsDataExist] = useState(false)

    useEffect(() => {
        const isDataExist = async () => {
            if (flow) {
                const data = await new DataService(flow).getDataByFlow()
                if ((data?.length ?? 0) > 0) setIsDataExist(true)
                else setIsDataExist(false)
            }
        }
        isDataExist()
    }, [flow])

    const onFirstViewEvent = (id: string) => {
        setFirstView(id)
    }

    useEffect(() => {
        fetchFlow()
    }, [flowId]) // Dependency array ensures the effect runs only when userId changes

    const MainView = () => {
        switch (tab) {
            case 0:
                return (
                    <FlowEditorArea
                        title={flow?.name}
                        flow={flow}
                        onFirstView={onFirstViewEvent}
                        viewDimensionsService={viewDimensionsService}
                    />
                )
            case 1:
                return <FlowDataArea flow={flow} />
            case 2:
                return <FlowSettingsArea flow={flow} />
            case 3:
                return <FlowShareArea flow={flow} />
            case 4:
                return <FlowStatisticsArea />
            case 5:
                return <FlowSessionArea flow={flow} />
        }
    }

    const insertUrlParam = (key: string, value: string) => {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set(key, value)
        const newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            '?' +
            searchParams.toString()
        window.history.pushState({ path: newurl }, '', newurl)
    }

    const setEditorTab = (_tab: number) => {
        insertUrlParam('tab', _tab.toString())
        setTab(_tab)
    }

    return (
        <div key={flowId} className='flowArea'>
            <div key={'title_' + flowId} className='flowHeaderComponent'>
                <FlowTitleComponent
                    text={flow?.name}
                    onChange={onTitleChangeEvent}
                />
                <Tooltip title='Preview Flow' arrow>
                    {isDataExist ? (
                        <Button
                            sx={{ float: 'right' }}
                            onClick={() => {
                                setEditorTab(1)
                            }}
                        >
                            <PreviewIcon />
                        </Button>
                    ) : (
                        <Button
                            sx={{ float: 'right' }}
                            onClick={() => {
                                setEditorTab(1)
                            }}
                        >
                            <Link
                                href={'/view/' + flowId}
                                target='_blank'
                                rel='noreferrer'
                                height='24px'
                            >
                                <PreviewIcon />
                            </Link>
                        </Button>
                    )}
                </Tooltip>
                <Tooltip title='Download Flow' arrow>
                    <Button
                        sx={{ float: 'right' }}
                        onClick={() => DownloadFlow(flowId)}
                    >
                        <DownloadIcon />
                    </Button>
                </Tooltip>

                <Button
                    sx={{
                        float: 'left',
                        backgroundColor: '#DDDDDD',
                        minWidth: 130,
                        margin: 1,
                    }}
                    onClick={() => {
                        setEditorTab(0)
                    }}
                >
                    <SchemaIcon />
                    <Typography fontSize={14}>Flow</Typography>
                </Button>
                <Button
                    sx={{
                        float: 'left',
                        backgroundColor: '#DDDDDD',
                        minWidth: 130,
                        margin: 1,
                    }}
                    onClick={() => {
                        setEditorTab(1)
                    }}
                >
                    <TocIcon />
                    <Typography fontSize={14}>Data</Typography>
                </Button>
                <Button
                    sx={{
                        float: 'left',
                        backgroundColor: '#DDDDDD',
                        minWidth: 130,
                        margin: 1,
                    }}
                    onClick={() => {
                        setEditorTab(2)
                    }}
                >
                    <SettingsIcon />
                    <Typography fontSize={14}>Settings</Typography>
                </Button>
                <Button
                    sx={{
                        float: 'left',
                        backgroundColor: '#DDDDDD',
                        minWidth: 130,
                        margin: 1,
                    }}
                    onClick={() => {
                        setEditorTab(3)
                    }}
                >
                    <ShareIcon />
                    <Typography fontSize={14}>Sharing</Typography>
                </Button>
                <Button
                    sx={{
                        float: 'left',
                        backgroundColor: '#DDDDDD',
                        minWidth: 130,
                        margin: 1,
                    }}
                    onClick={() => {
                        setEditorTab(4)
                    }}
                >
                    <InsertChartIcon />
                    <Typography fontSize={14}>Statistics</Typography>
                </Button>
                <Button
                    sx={{
                        float: 'left',
                        backgroundColor: '#DDDDDD',
                        minWidth: 130,
                        margin: 1,
                    }}
                    onClick={() => {
                        setEditorTab(5)
                    }}
                >
                    <InsertChartIcon />
                    <Typography fontSize={14}>Sessions</Typography>
                </Button>
            </div>
            {MainView()}
        </div>
    ) //TODO: Add FlowDescriptionElement in flowHeaderComponent
}

export default FlowComponent
