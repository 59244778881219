import { Font } from '../models/fontModel'
import apiService from './APIService'

export class FontService {
    public async getFonts(): Promise<Font[] | undefined> {
        return apiService.jsonRequest('/media/fonts', 'GET')
    }

    public async createFont(body: FormData): Promise<string | undefined> {
        return apiService.binRequest('/media/fonts', 'POST', body)
    }

    public async useFont(fontName: string): Promise<void> {
        const fontFileName = (await this.getFonts())?.find(
            font => font.fontName === fontName,
        )
        if (!fontFileName) {
            return
        }
        const font = await apiService.getBlob(`/fonts/${fontFileName.fileName}`)
        if (!font) {
            return
        }
        this.saveFont(font, fontName)
    }

    private saveFont(blob: Blob, fontName: string): void {
        const url = URL.createObjectURL(blob)
        const style = document.createElement('style')
        style.textContent = `
            @font-face {
                font-family: '${fontName}';
                src: url(${url});
            }

            body {
                font-family: '${fontName}', sans-serif;
            }
        `
        document.head.appendChild(style)
    }
}

export default new FontService()
