import { ViewType } from './viewType'

export interface View {
    id?: string
    externalId?: number
    title?: string
    type?: ViewType
    description?: string
    method?: string
    parameters?: ViewProps
    style?: ViewStyle
    flow?: string // Refers to the Flow ID
}

export class ViewAndDimensions {
    public view?: View
    public x?: number
    public y?: number
    public width?: number
    public height?: number

    constructor(init: Partial<ViewAndDimensions>) {
        Object.assign(this, init)
    }

    public fold() {
        if (this.view && this.view?.style) {
            console.log('Folding View:  ' + this.view.title)
            this.view.style.folded = true
        }
    }

    public unfold() {
        if (this.view && this.view?.style) {
            console.log('UnFolding View:  ' + this.view.title)
            this.view.style.folded = false
        }
    }
}

export enum EmbeddedType {
    EMBED = 0,
    EXTERNAL = 1,
    REDIRECT = 2,
}

export interface EmbeddedProps {
    embedType: EmbeddedType
    url: string
}

export enum UIInputType {
    Text,
    Number,
    Date,
}

export interface UIInputField {
    index: number
    name: string
    variable: string
    type: UIInputType
}

export interface DescriptionViewProps {
    inputs: UIInputField[]
}

export interface ViewStyle {
    folded: boolean
}

export interface ViewProps {
    httpProps?: HttpProps
    logicProps?: LogicProps
    embeddedProps?: EmbeddedProps
    descriptionProps?: DescriptionViewProps
}

export interface HttpHeaderProps {
    name: string
    value: string
}

export interface HttpProps {
    body?: string
    headers: HttpHeaderProps[]
    sessionVariable?: string
    url: string
    method: string
}

export interface LogicProps {
    sessionValue?: string
}

export interface RedirectView {
    target: string
    flowId: string
    sessionId: string
    dataId: string
}

export interface ViewOrRedirect {
    isRedirect: boolean
    view?: View
    redirect?: RedirectView
}

export class ModelDefaults {
    public static defaultEmbeddedProps: EmbeddedProps = {
        embedType: EmbeddedType.EMBED,
        url: 'https://google.de',
    }

    public static defaultHttpProps: HttpProps = {
        headers: [],
        body: '',
        sessionVariable: '',
        url: 'https://google.de',
        method: 'GET',
    }

    public static redirectView: RedirectView = {
        target: '',
        flowId: '',
        dataId: '',
        sessionId: '',
    }
}
