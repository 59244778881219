import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    TableCell,
    TableRow,
} from '@mui/material'
import { UIInputField, UIInputType, View } from '../../models/viewModel'
import { useState } from 'react'
import TypeInputField from '../Base/TypeInputField/TypeInputField'
import { GridDeleteIcon } from '@mui/x-data-grid'

export interface InputSelectProps {
    view: View
    index: number
    input: UIInputField
    onChangeInput: (index: number, input: UIInputField) => Promise<void>
    onDeleteInput: (index: number) => Promise<void>
}

const InputSelect = ({
    index,
    input,
    onChangeInput,
    onDeleteInput,
}: InputSelectProps) => {
    const [_input, setInput] = useState<UIInputField>(input)

    const onChangeNameEvent = (value: string) => {
        _input.name = value

        onChangeInput(index, _input)
    }

    const onChangeVariableEvent = (value: string) => {
        _input.variable = value

        onChangeInput(index, _input)
    }

    function onSelectInputTypeEvent(
        event: SelectChangeEvent<UIInputType>,
    ): void {
        const updatedInput = {
            ..._input,
            type: event.target.value as UIInputType,
        }
        setInput(updatedInput)
        onChangeInput(index, updatedInput)
    }

    async function onDeleteClick(): Promise<void> {
        return onDeleteInput(_input.index)
    }

    return (
        <TableRow key={'input_field_nr_' + index}>
            <TableCell>
                <TypeInputField onBlur={onChangeNameEvent} text={input.name} />
            </TableCell>
            <TableCell>
                <TypeInputField
                    onBlur={onChangeVariableEvent}
                    text={input.variable}
                />
            </TableCell>
            <TableCell>
                <Select value={_input.type} onChange={onSelectInputTypeEvent}>
                    <MenuItem value={UIInputType.Text}>Text</MenuItem>
                    <MenuItem value={UIInputType.Number}>Number</MenuItem>
                    <MenuItem value={UIInputType.Date}>Date</MenuItem>
                </Select>
            </TableCell>
            <TableCell>
                <Button onClick={onDeleteClick}>
                    <GridDeleteIcon />
                </Button>
            </TableCell>
        </TableRow>
    )
}

export default InputSelect
