import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Flow } from '../../models/flowModel'
import { Tooltip } from '@mui/material'

interface DeleteFlowAlertProps {
    onOK: (flow: Flow) => void
    onCancel?: (flow: Flow) => void
    children: JSX.Element
    callbackParameter: Flow
    title: string
    text: string
    tooltip?: string
}

export default function OkCancelDialogButton({
    callbackParameter,
    title,
    text,
    children,
    onOK,
    onCancel,
    tooltip,
}: DeleteFlowAlertProps) {
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = (result: boolean) => {
        if (result) {
            onOK(callbackParameter)
        } else {
            if (onCancel !== undefined) {
                onCancel(callbackParameter)
            }
        }
        setOpen(false)
    }

    return (
        <>
            <Tooltip title={tooltip} arrow>
                <Button onClick={handleOpen}>{children}</Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => {
                    handleClose(false)
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleClose(true)
                        }}
                    >
                        OK
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose(false)
                        }}
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
