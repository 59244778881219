import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    TableCell,
    TableRow,
} from '@mui/material'
import {
    LogicSelectProps,
    LogicSelectType,
    SelectDefaults,
    SelectModel,
} from '../../models/selectModel'
import { OnAddViewResult } from './SelectTargetComponent'
import { useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { View } from '../../models/viewModel'
import { SelectService } from '../../services/SelectService'
import { ViewService } from '../../services/ViewService'
import SelectTargetComponent from './SelectTargetComponent'
import TypeInputField from '../Base/TypeInputField/TypeInputField'

export interface SelectLogicComponentProps {
    _select: SelectModel
    editor: unknown
    onDelete: (id: string) => void
    onAddViewCallback: (
        viewId: string,
        title: string,
    ) => Promise<OnAddViewResult>
    viewService: ViewService
    selectService: SelectService
}

const SelectLogicComponent = ({
    _select,
    onDelete,
    onAddViewCallback,
    viewService,
    selectService,
}: SelectLogicComponentProps) => {
    const [views, setViews] = useState<View[]>([])
    if (_select?.response?.httpProps === undefined) {
        _select.response.httpProps = SelectDefaults.DefaultHttpProps
    }
    const [select, setSelect] = useState<SelectModel>(_select)
    const [logicProps, setLogicProps] = useState<LogicSelectProps>(
        select.response.logicProps ?? SelectDefaults.DefaultLogicProps,
    )

    useEffect(() => {
        if (views.length === 0) {
            const loadViews = async () => {
                const updatedViews = await viewService.getAll()
                setViews(updatedViews ?? [])
            }
            loadViews()
        }
    }, [views, viewService])

    function Delete() {
        if (select.id !== undefined) {
            onDelete(select.id)
        }
    }

    function onAddViewEvent(
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> {
        return onAddViewCallback(viewId, title)
    }

    const updateSelect = async (_select: SelectModel) => {
        if (_select !== null && _select.id !== null) {
            setSelect(_select)
            await selectService.updateSelect(_select.id ?? '', _select)
        }
    }

    const onSelectViewEvent = async (viewId: string) => {
        if (select.id !== undefined) {
            const updatedSelect: SelectModel = {
                ...select,
                target: viewId,
            }
            await updateSelect(updatedSelect)
        } else {
            console.log('ERROR: Select has no ID!')
        }
    }

    // Function to handle the change event
    const onSelectOperation = async (
        event: SelectChangeEvent<LogicSelectType>,
    ) => {
        // Get the selected value from the event object
        const newValue = event.target.value as LogicSelectType

        const updatedProps: LogicSelectProps = { ...logicProps, type: newValue }
        const updatedSelect = {
            ...select,
            response: { logicProps: updatedProps },
        }
        await updateSelect(updatedSelect)
        setLogicProps(updatedProps)
    }

    const onChangeVariableEvent = async (text: string) => {
        const updatedProps = {
            ...logicProps,
            value: text,
        }
        await updateSelect({
            ...select,
            response: { logicProps: updatedProps },
        })
        setLogicProps(updatedProps)
    }

    return (
        <>
            <TableRow key={select.id}>
                <TableCell sx={{ height: '20px', width: '40%' }}>
                    <Select
                        value={logicProps.type}
                        onChange={onSelectOperation}
                    >
                        <MenuItem value={LogicSelectType.Default}>
                            Default
                        </MenuItem>
                        <MenuItem value={LogicSelectType.Less}>&lt;</MenuItem>
                        <MenuItem value={LogicSelectType.More}>&gt;</MenuItem>
                        <MenuItem value={LogicSelectType.MoreOrEqual}>
                            &gt;=
                        </MenuItem>
                        <MenuItem value={LogicSelectType.LessOrEqual}>
                            &lt;=
                        </MenuItem>
                        <MenuItem value={LogicSelectType.Equal}>=</MenuItem>
                        <MenuItem value={LogicSelectType.StartsWith}>
                            startsWith
                        </MenuItem>
                        <MenuItem value={LogicSelectType.EndsWith}>
                            endsWith
                        </MenuItem>
                        <MenuItem value={LogicSelectType.Contains}>
                            contains
                        </MenuItem>
                        <MenuItem value={LogicSelectType.Before}>
                            before
                        </MenuItem>
                        <MenuItem value={LogicSelectType.After}>after</MenuItem>
                    </Select>
                </TableCell>
                <TableCell>
                    <TypeInputField
                        text={select?.response?.logicProps?.value}
                        onBlur={onChangeVariableEvent}
                    />
                </TableCell>
                <TableCell sx={{ height: '20px', width: '40%' }}>
                    <SelectTargetComponent
                        select={select}
                        viewService={viewService}
                        onAddViewCallback={onAddViewEvent}
                        onSelect={onSelectViewEvent}
                    />
                </TableCell>
                <TableCell sx={{ height: '20px', width: '20%' }}>
                    <Button
                        className='DeleteSelectButton'
                        onClick={() => {
                            Delete()
                        }}
                    >
                        <DeleteForeverIcon />
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SelectLogicComponent
