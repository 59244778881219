import env from '@beam-australia/react-env'
import { AuthConfig } from '../services/AuthService'

const authConfig: AuthConfig = {
    url: env('AUTH_URL') ?? 'http://localhost:8080',
    realm: env('AUTH_REALM') ?? 'clickguide',
    clientId: env('AUTH_CLIENT_ID') ?? 'clickguide',
}

export default authConfig
