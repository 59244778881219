import { Box, Button, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import AuthService from '../../services/AuthService'
import { isInitializedSelector } from '../../state/AuthSlice'

interface LoginRequiredProps {
    children: JSX.Element
}
export default function LoginRequired({ children }: LoginRequiredProps) {
    const isInitialized = useSelector(isInitializedSelector)

    const authService = AuthService.getInstance()

    if (!isInitialized || !authService?.isLoggedIn) {
        console.log(
            'Initialized: ' +
                isInitialized +
                ', login: ' +
                authService?.isLoggedIn,
        )
        return (
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant='h6'>
                    This tool requires authorization
                </Typography>
                <Button
                    sx={{ mt: 2 }}
                    size='large'
                    variant='contained'
                    onClick={() => authService?.login() ?? alert("AuthObject does not exist")}
                >
                    Please login
                </Button>
            </Box>
        )
    }

    return children
}
