import Sidebar from './Sidebar/Sidebar'
import TopBar from './TopBar/TopBar'
import FlowListComponent from './FlowList/FlowListComponent'
import MainComponent from './Main/MainComponent'
import SecretsComponent from './Secrets/SecretsComponent'
import MediaComponent from './Media/MediaComponent'
import FlowComponent from './Flow/FlowComponent'
import { useParams } from 'react-router-dom'
import LoginRequired from './Base/LoginRequired'
import CustomerInfoComponent from './CustomerInfo/CustomerInfoComponent'

interface EditorBaseProps {
    type: string
}

const EditorBase = ({ type }: EditorBaseProps) => {
    const { flowId } = useParams()

    const RenderPage = () => {
        switch (type) {
            case 'flows':
                return <FlowListComponent />
            case 'flow':
                return <FlowComponent flowId={flowId ?? ''} />
            case 'secrets':
                return <SecretsComponent />
            case 'media':
                return <MediaComponent />
            case 'companydata':
                return <CustomerInfoComponent />
        }
    }

    return (
        <>
            <TopBar />
            <LoginRequired>
                <div className='app-body'>
                    <Sidebar />
                    <MainComponent>{RenderPage()}</MainComponent>
                </div>
            </LoginRequired>
        </>
    )
}

export default EditorBase
