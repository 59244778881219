import {
    Button,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import Select from '@mui/material/Select'

import { View } from '../../models/viewModel'
import { useEffect, useRef, useState } from 'react'
import TypeInputField from '../Base/TypeInputField/TypeInputField'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { ViewService } from '../../services/ViewService'
import { ViewType } from '../../models/viewType'
import DescriptionView from './DescriptionView'
import HttpView from './HttpView'
import LogicView from './LogicView'
import FileView from './FileView'
import { OnAddViewResult } from '../Select/SelectTargetComponent'
import SelectionsMiniList from './SelectionsMiniList'
import EmbeddedView from './EmbeddedView'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import LanguageIcon from '@mui/icons-material/Language'
import CalculateIcon from '@mui/icons-material/Calculate'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import WarningIcon from '@mui/icons-material/Warning'
import { v4 as uuidv4 } from 'uuid'

interface ViewComponentParams {
    elkey: string
    view: View
    id: string
    x: number
    y: number
    folded: boolean
    onDimensions: (id: string, width: number, height: number) => void
    onChange: (view: View) => void
    onDelete: (view: View) => void
    onAddView: (viewIds: string, title: string) => Promise<OnAddViewResult>
    flowEditorArea: unknown
    viewService: ViewService
}

const ViewComponent = ({
    elkey,
    view,
    id,
    x,
    y,
    folded,
    onDimensions,
    onChange,
    onDelete,
    onAddView,
    flowEditorArea,
    viewService,
}: ViewComponentParams) => {
    const ref = useRef(null)
    const [posx, setPosX] = useState(x)
    const [posy, setPosY] = useState(y)
    const [_view, setView] = useState<View>(view)
    const [isMinimized, setIsMinimized] = useState<boolean>(
        view.style !== undefined ? view.style.folded : false,
    )

    const Delete = async () => {
        onDelete(_view)
    }

    interface DivSize {
        width: number | undefined
        height: number | undefined
    }

    function useResizeObserver(ref: React.RefObject<Element>): DivSize {
        const [size, setSize] = useState<DivSize>({
            width: undefined,
            height: undefined,
        })

        useEffect(() => {
            if (ref.current) {
                const observeTarget = ref.current
                const resizeObserver = new ResizeObserver(entries => {
                    entries.forEach(entry => {
                        setSize({
                            width: entry.contentRect.width,
                            height: entry.contentRect.height,
                        })
                        if (
                            size.width !== undefined &&
                            size.height !== undefined
                        ) {
                            onDimensions(id, size.width, size.height)
                        }
                    })
                })

                resizeObserver.observe(observeTarget)
                return () => resizeObserver.unobserve(observeTarget)
            }
        }, [ref, size.height, size.width])

        return size
    }

    const dimension: DivSize = useResizeObserver(ref)

    const OnSelectViewType = (event: SelectChangeEvent) => {
        const updatedView = {
            ..._view, // spread the existing view properties
            type: event.target.value as ViewType, // update the type property
        }
        setView(updatedView)
        onChange(updatedView)
    }

    const OnBlurTitle = async (text: string) => {
        const updatedView: View = {
            ..._view,
            title: text,
        }
        if (_view.id !== undefined) {
            setView(updatedView)

            await viewService.updateView(_view.id, updatedView)
        }
    }

    useEffect(() => {
        if (ref.current) {
            const { offsetWidth, offsetHeight } = ref.current
            onDimensions(id, offsetWidth, offsetHeight)
        }
    }, [id, onDimensions, ref])

    useEffect(() => {
        setPosX(x)
        setPosY(y)
    }, [x, y])

    useEffect(() => {
        onDimensions(id, dimension.width ?? 0, dimension.height ?? 0)
    }, [dimension, id, onDimensions])

    useEffect(() => {
        if (view.style) {
            setIsMinimized(folded)
        }
    }, [folded, view.style])

    const OnAddViewEvent = (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        if (onAddView !== undefined) {
            return onAddView(viewId, title)
        }
        //TODO; Always return something
        return Promise.resolve({
            _views: [],
            id: '',
        })
    }

    const OnChangeView = async (updatedView: View) => {
        setView(updatedView)
        await viewService.updateView(updatedView.id ?? '', updatedView)
    }

    const Open = () => {
        setIsMinimized(false)
        if (_view.style?.folded === true) {
            OnChangeView({
                ..._view,
                style: {
                    folded: false,
                },
            })
        }
    }

    const Close = () => {
        setIsMinimized(true)
        if (_view.style?.folded === false) {
            OnChangeView({
                ..._view,
                style: {
                    folded: true,
                },
            })
        }
    }

    function RenderViewContent() {
        switch (_view.type) {
            case ViewType.Error:
                return (
                    <DescriptionView
                        flowEditor={flowEditorArea}
                        view={_view}
                        key={'view_main_' + _view.id}
                        viewService={viewService}
                        onAddView={OnAddViewEvent}
                        onChangeView={OnChangeView}
                    />
                )
            case ViewType.View:
                return (
                    <DescriptionView
                        flowEditor={flowEditorArea}
                        view={_view}
                        key={'view_main_' + (_view.id ?? 0)}
                        viewService={viewService}
                        onAddView={OnAddViewEvent}
                        onChangeView={OnChangeView}
                    />
                )
            case ViewType.HttpCall:
                return (
                    <HttpView
                        flowEditor={flowEditorArea}
                        view={_view}
                        key={'view_main_' + (_view.id ?? 0)}
                        viewService={viewService}
                        onAddView={OnAddViewEvent}
                        onChangeView={OnChangeView}
                    />
                )
            case ViewType.File:
                return (
                    <FileView
                        flowEditor={flowEditorArea}
                        view={_view}
                        key={'view_main_' + (_view.id ?? 0)}
                        viewService={viewService}
                        onAddView={OnAddViewEvent}
                        onChangeView={OnChangeView}
                    />
                )
            case ViewType.Logic:
                return (
                    <LogicView
                        flowEditor={flowEditorArea}
                        view={_view}
                        key={'view_main_' + (_view.id ?? uuidv4())}
                        viewService={viewService}
                        onAddView={OnAddViewEvent}
                        onChangeView={OnChangeView}
                    />
                )
            case ViewType.Embed:
                return (
                    <EmbeddedView
                        flowEditor={flowEditorArea}
                        view={_view}
                        key={'view_main_' + (_view.id ?? 0)}
                        viewService={viewService}
                        onChangeView={OnChangeView}
                        onAddView={OnAddViewEvent}
                    />
                )
        }
    }

    function RenderMiniContent() {
        return (
            <SelectionsMiniList
                viewService={viewService}
                view={_view}
                key={'view_mini_' + (_view.id ?? 0)}
            />
        )
    }

    const DeleteButton = () => {
        if (view.externalId === 0) {
            return
        }

        return (
            <Button onClick={() => Delete()} sx={{ float: 'right' }}>
                <CloseIcon />
            </Button>
        )
    }

    const BigView = () => {
        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Select
                                onChange={OnSelectViewType}
                                value={_view.type}
                            >
                                <MenuItem value={'View'}>View</MenuItem>
                                <MenuItem value={'Embed'}>
                                    Embed Website
                                </MenuItem>
                                <MenuItem value={'Http'}>HttpRequest</MenuItem>
                                <MenuItem value={'Logic'}>Logic</MenuItem>
                                <MenuItem value={'Error'}>Error</MenuItem>
                            </Select>
                            <ArrowUpward
                                onClick={() => {
                                    Close()
                                }}
                                sx={{ float: 'right' }}
                            />
                            {DeleteButton()}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography>Title</Typography>
                            <TypeInputField
                                text={_view.title}
                                onBlur={OnBlurTitle}
                            />
                        </TableCell>
                    </TableRow>
                    {RenderViewContent()}
                </TableBody>
            </Table>
        )
    }

    const ViewTypeIcon = (view: View) => {
        switch (view.type) {
            case ViewType.View:
                return <TextSnippetIcon />
            case ViewType.Embed:
                return <OpenInNewIcon />
            case ViewType.HttpCall:
                return <LanguageIcon />
            case ViewType.Logic:
                return <CalculateIcon />
            case ViewType.File:
                return <PermMediaIcon />
            case ViewType.Error:
                return <WarningIcon />
        }
    }

    const SmallView = () => {
        return (
            <Table width={'100%'}>
                <TableBody>
                    <TableRow>
                        <TableCell width={'100%'}>
                            <Button
                                onClick={() => {
                                    Open()
                                }}
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    display: 'block',
                                }}
                            >
                                <Typography sx={{ float: 'left' }}>
                                    {ViewTypeIcon(_view)}
                                    {_view.title ?? ''}
                                </Typography>
                                <ArrowDownward sx={{ float: 'right' }} />
                            </Button>
                        </TableCell>
                    </TableRow>
                    {RenderMiniContent()}
                </TableBody>
            </Table>
        )
    }

    return (
        <div
            id={elkey}
            key={elkey}
            ref={ref}
            className='viewComponent'
            style={{
                position: 'absolute',
                left: `${posx ?? 0}px`,
                top: `${posy ?? 0}px`,
            }}
        >
            {!isMinimized && BigView()}
            {isMinimized && SmallView()}
        </div>
    )
}

export default ViewComponent
