import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { SelectModel } from '../../models/selectModel'
import { View } from '../../models/viewModel'
import { ViewService } from '../../services/ViewService'
import { useEffect, useState } from 'react'
import React from 'react'
import StringInputDialog from '../Alert/StringInputDialog'

export interface OnAddViewResult {
    id: string
    _views: View[]
}

export interface SelectTargetComponentProps {
    select: SelectModel
    viewService: ViewService
    onSelect: (id: string) => void
    onAddViewCallback: (
        parentViewId: string,
        title: string,
    ) => Promise<OnAddViewResult>
}

const SelectTargetComponent = ({
    select,
    viewService,
    onSelect,
    onAddViewCallback,
}: SelectTargetComponentProps) => {
    const [_views, setViews] = useState<View[]>([])
    const [selectedValue, setSelectedValue] =
        React.useState<string>('undefined')
    const [isAddDialogOpen, setIsAddDataDialogOpen] =
        React.useState<boolean>(false)

    useEffect(() => {
        const loadViews = async () => {
            const updatedViews = await viewService.getAll()
            setViews(updatedViews ?? [])
            setSelectedValue(select.target ?? '')
        }
        loadViews()
    }, [select.target, viewService])

    // Function to handle the change event
    const onSelectTarget = async (event: SelectChangeEvent<string>) => {
        // Get the selected value from the event object
        const result = event.target.value
        if (result === 'new') {
            //NEW
            setIsAddDataDialogOpen(true)
        } else {
            if (onSelect !== undefined) {
                onSelect(result)
                setSelectedValue(result)
            }
        }

        // You can add more logic here if needed
    }

    const AddView = async (title: string) => {
        const updatedViews = await onAddViewCallback(select.view ?? '', title)
        setViews(updatedViews._views)
        select.target = updatedViews.id
        setSelectedValue(updatedViews.id)
        onSelect(updatedViews.id)
        setIsAddDataDialogOpen(false)
    }

    const CloseDialog = () => {
        setIsAddDataDialogOpen(false)
    }

    return (
        <>
            <Select
                key={'Select_' + select.id}
                value={selectedValue}
                onChange={onSelectTarget}
                displayEmpty
            >
                <MenuItem
                    key={'select_' + select.id + '_none'}
                    value={'undefined'}
                >
                    <Button>Undefined</Button>
                </MenuItem>
                <MenuItem key={'select_' + select.id + '_add'} value={'new'}>
                    <Button>New</Button>
                </MenuItem>
                {_views.map(entry => {
                    return (
                        <MenuItem
                            key={'select_' + select.id + '_' + entry.id}
                            value={entry.id}
                        >
                            {entry.title}
                        </MenuItem>
                    )
                })}
            </Select>
            <StringInputDialog
                isOpen={isAddDialogOpen}
                defaultValue='NewView'
                onClose={CloseDialog}
                onSubmit={AddView}
                text='Enter title'
                title='Add view'
            />
        </>
    )
}

export default SelectTargetComponent
