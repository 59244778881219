import TypeInputField from '../Base/TypeInputField/TypeInputField'

interface FlowTitleComponentProps {
    text: string | undefined
    onChange: (title: string) => void
}

const FlowTitleComponent = ({ text, onChange }: FlowTitleComponentProps) => {
    const onChangeEvent = (text: string) => {
        if (onChange !== undefined) {
            onChange(text)
        }
    }

    return <TypeInputField text={text} onBlur={onChangeEvent} />
}

export default FlowTitleComponent
