import { ReactNode, useEffect, useMemo, useState } from 'react'
import { SessionService } from '../../services/SessionService'
import { Session } from '../../models/sessionModel'
import { Flow } from '../../models/flowModel'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { GridDeleteIcon } from '@mui/x-data-grid'
import SyncIcon from '@mui/icons-material/Sync'
import OkCancelDialogButton from '../Alert/OkCancelDialogButton'

export interface FlowSessionAreaProps {
    flow: Flow
}

const FlowSessionArea = ({ flow }: FlowSessionAreaProps) => {
    const [_flow] = useState<Flow>(flow)
    const [sessions, setSessions] = useState<Session[]>()
    const sessionService = useMemo(() => new SessionService(), [])
    const [, setShowDeleteSessionsDialog] = useState<boolean>(false)

    useEffect(() => {
        const fetchFlows = async () => {
            const _sessions = await sessionService.getAllSessionsByFlow(
                _flow.id ?? '',
            )
            setSessions(_sessions)
        }
        fetchFlows()
    }, [_flow, sessionService])

    const reloadSessions = () => {
        const fetchFlows = async () => {
            const _sessions = await sessionService.getAllSessionsByFlow(
                _flow.id ?? '',
            )
            setSessions(_sessions)
        }
        fetchFlows()
    }

    const deleteSessions = async () => {
        await sessionService.deleteAllSessionsForFlow(_flow?.id ?? '')
        setSessions([])
        setShowDeleteSessionsDialog(false)
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Typography variant='h5'>Sessions</Typography>
                    <OkCancelDialogButton
                        onOK={deleteSessions}
                        title='Delete flow?'
                        text={`Are you sure, you want to delete all Sessions from Flow  '${flow.name}'<br>This <b>cannot be undone!</b>`}
                        callbackParameter={flow}
                    >
                        <Button
                            color='primary'
                            startIcon={<GridDeleteIcon />}
                            onClick={() => {
                                setShowDeleteSessionsDialog(true)
                            }}
                        >
                            Delete
                        </Button>
                    </OkCancelDialogButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button
                        color='primary'
                        startIcon={<SyncIcon />}
                        onClick={reloadSessions}
                    >
                        Reload all
                    </Button>
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>Variables</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessions &&
                        sessions.map(entry => {
                            return (
                                <TableRow key={'session_row_' + entry.id}>
                                    <TableCell>{entry.id}</TableCell>
                                    <TableCell>
                                        {entry.start?.toString()}
                                    </TableCell>
                                    <TableCell>
                                        {entry.variables &&
                                            Object.entries(entry.variables).map(
                                                ([key, value]) => {
                                                    return (
                                                        <p
                                                            key={
                                                                'session_' +
                                                                entry.id +
                                                                '_var_' +
                                                                key
                                                            }
                                                        >
                                                            <b>{key}:</b>
                                                            {value as ReactNode}
                                                        </p>
                                                    )
                                                },
                                            )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </div>
    )
}

export default FlowSessionArea
