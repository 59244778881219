import { useState } from 'react'

interface SelfInputFieldProps {
    onChange: (value: string) => void
    value: string
}

const SelfInputField = ({ value, onChange }: SelfInputFieldProps) => {
    const [_value, setValue] = useState<string>(value)
    return (
        <input
            onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
            }}
            value={_value}
        />
    )
}

export default SelfInputField
