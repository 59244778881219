import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import EditorBase from './components/EditorBase'
import MainPage from './components/Page/MainPage'
import { Provider } from 'react-redux'
import { store } from './state/store'
import { startInit } from './state/AuthSlice'
import AuthService from './services/AuthService'

let intervalActive = false
const App = () => {
    useEffect(() => {
        startInit(store.dispatch)

        setInterval(() => {
            if (intervalActive === false) {
                const service = AuthService.getInstance()
                if (service) {
                    if (service.isLoggedIn) {
                        setInterval(() => {
                            const service = AuthService.getInstance()
                            service?.refresh()
                        }, 200000) //TODO Parametrize
                    }
                }
                intervalActive = true
            }
        }, 10000)
        // eslint-disable-next-line
    }, [])
    return (
        <div className='App'>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            id='flows'
                            path='/'
                            element={<EditorBase type={'flows'} />}
                        />
                        <Route
                            id='flows'
                            path='/edit/flows'
                            element={<EditorBase type={'flows'} />}
                        />
                        <Route
                            id='flow'
                            path='/edit/flows/:flowId'
                            element={<EditorBase type={'flow'} />}
                        />
                        <Route
                            id='media'
                            path='/edit/media'
                            element={<EditorBase type={'media'} />}
                        />
                        <Route
                            id='data'
                            path='/edit/secrets'
                            element={<EditorBase type={'secrets'} />}
                        />
                        <Route
                            id='view'
                            path='/view/:flowId'
                            element={<MainPage />}
                        />
                        <Route
                            id='view'
                            path='/view/:flowId/:viewId'
                            element={<MainPage />}
                        />
                        <Route
                            id='companydata'
                            path='/edit/companydata'
                            element={<EditorBase type={'companydata'} />}
                        />
                    </Routes>
                </BrowserRouter>
            </Provider>
        </div>
    )
}

export default App
