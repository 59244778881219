import APIService from './APIService'
import { SelectModel } from '../models/selectModel' // Adjust the import path as necessary

export class SelectService {
    private viewId: string
    constructor(_viewId: string) {
        this.viewId = _viewId
    }

    public async getAll(
        data: string | null = null,
    ): Promise<SelectModel[] | undefined> {
        try {
            if (data !== null) {
                return await APIService.jsonRequest(
                    `/selects/view/${this.viewId}?data=${data}`,
                    'GET',
                )
            }
            return await APIService.jsonRequest(
                `/selects/view/${this.viewId}`,
                'GET',
            )
        } catch (error) {
            return []
        }
    }

    public async getSelectById(
        id: string,
        data: string | null = null,
    ): Promise<SelectModel | undefined> {
        if (data !== null) {
            return APIService.jsonRequest(`/selects/${id}?data=${data}`, 'GET')
        }
        return APIService.jsonRequest(`/selects/${id}`, 'GET')
    }

    public async createSelect(
        selectData: SelectModel,
    ): Promise<SelectModel | undefined> {
        return APIService.jsonRequest('/selects', 'POST', selectData)
    }

    public async updateSelect(
        _: string,
        selectData: SelectModel,
    ): Promise<SelectModel | undefined> {
        return APIService.jsonRequest(`/selects`, 'PUT', selectData)
    }

    public async deleteSelect(id: string): Promise<void> {
        return APIService.jsonRequest(`/selects/${id}`, 'DELETE', null, false)
    }
}
