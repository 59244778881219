export const Download = (name: string, data: string) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([data]))
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    if (!link || !link.parentNode) {
        return
    }
    // Clean up and remove the link
    link?.parentNode?.removeChild(link)
}
