import { Button, TableCell, TableRow } from '@mui/material'
import { SelectModel } from '../../models/selectModel'
import { OnAddViewResult } from './SelectTargetComponent'
import TypeTextField from '../Base/TypeInputField/TypeInputField'
import { useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { View } from '../../models/viewModel'
import { SelectService } from '../../services/SelectService'
import { ViewService } from '../../services/ViewService'
import SelectTargetComponent from './SelectTargetComponent'

export interface SelectComponentProps {
    _select: SelectModel
    editor: unknown
    onDelete: (selectId: string) => void
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
    viewService: ViewService
    selectService: SelectService
}

const SelectViewComponent = ({
    _select,
    onDelete,
    onAddView,
    viewService,
    selectService,
}: SelectComponentProps) => {
    const [views, setViews] = useState<View[]>([])
    const [select, setSelect] = useState<SelectModel>(_select)

    useEffect(() => {
        if (views.length === 0) {
            const loadViews = async () => {
                const updatedViews = await viewService.getAll()
                setViews(updatedViews ?? [])
            }
            loadViews()
        }
    }, [views, viewService])

    function Delete() {
        if (select.id !== undefined) {
            onDelete(select.id)
        }
    }

    function onTitleBlur(text: string) {
        const updatedSelect: SelectModel = {
            ...select,
            title: text,
        }
        setSelect(updatedSelect)
        selectService.updateSelect(select.id ?? '', updatedSelect)
    }

    function onAddViewEvent(
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> {
        return onAddView(viewId, title)
    }

    function onSelectViewEvent(viewId: string) {
        if (select.id !== undefined) {
            const updatedSelect: SelectModel = {
                ...select,
                target: viewId,
            }
            setSelect(updatedSelect)
            selectService.updateSelect(updatedSelect.id ?? '', updatedSelect)
        } else {
            console.log('ERROR: Select has no ID!')
        }
    }

    return (
        <>
            <TableRow key={select.id}>
                <TableCell sx={{ height: 3, width: '40%' }}>
                    <TypeTextField text={select.title} onBlur={onTitleBlur} />
                </TableCell>
                <TableCell sx={{ height: 3, width: '40%' }}>
                    <SelectTargetComponent
                        select={select}
                        viewService={viewService}
                        onAddViewCallback={onAddViewEvent}
                        onSelect={onSelectViewEvent}
                    />
                </TableCell>
                <TableCell sx={{ height: 3, width: '20%' }}>
                    <Button className='DeleteSelectButton' onClick={Delete}>
                        <DeleteForeverIcon />
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SelectViewComponent
