import { Typography } from '@mui/material'
import { Flow } from '../../models/flowModel'

export interface FlowStatisticsAreaProps {
    flow: Flow
}

const FlowStatisticsArea = () => {
    return (
        <div>
            <Typography variant='h4'>Statistics</Typography>
            TODO
        </div>
    )
}

export default FlowStatisticsArea
