import { useCallback, useState } from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import TypeInputField from './TypeInputField/TypeInputField'
import { HttpHeaderProps } from '../../models/viewModel'

export interface HttpHeaderViewProps extends HttpHeaderProps {
    onChange?: (key: string, value: string) => void
    onDelete?: (key: string) => void
}

const HttpHeaderEntry = ({ value, name }: HttpHeaderViewProps) => {
    const [_value] = useState<string>(value)
    const [_key] = useState<string>(name)

    const OnDelete = useCallback((key: string) => {
        if (!key) return
        // TODO: implement
    }, [])

    const onNameChange = useCallback((value: unknown) => {
        if (!value) return
        // TODO: implement
    }, [])
    const onValueChange = useCallback((value: unknown) => {
        if (!value) return
        // TODO: implement
    }, [])

    return (
        <TableRow>
            <TableCell sx={{ height: 2, width: '45%' }}>
                <TypeInputField text={_key} onBlur={onNameChange} />
            </TableCell>
            <TableCell sx={{ height: 2, width: '45%' }}>
                <TypeInputField text={_value} onBlur={onValueChange} />
            </TableCell>
            <TableCell sx={{ height: 2, width: '10%' }}>
                <Button
                    onClick={() => {
                        OnDelete(_key)
                    }}
                >
                    -
                </Button>
            </TableCell>
        </TableRow>
    )
}

export default HttpHeaderEntry
