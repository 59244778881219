import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Tooltip } from '@mui/material'

interface UploadFileDialogProperties {
    onOK: (input: string, file: File) => void
    onCancel: () => void
    children: JSX.Element
    title: string
    tooltip?: string
}

export default function FileUploadDialogButton({
    title,
    children,
    onOK,
    onCancel,
    tooltip,
}: UploadFileDialogProperties) {
    const [open, setOpen] = React.useState(false)
    const [input, setInput] = React.useState('')
    const [file, setFile] = React.useState<File | undefined>()

    function onUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length === 1
        ) {
            setFile(event?.target?.files[0])
            setInput(event?.target?.files[0].name)
        }
    }
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = (result: boolean) => {
        if (result) {
            if (file !== undefined) {
                onOK(input, file)
            } else {
                onCancel()
            }
        } else {
            if (onCancel !== undefined) {
                onCancel()
            }
        }
        setOpen(false)
    }

    return (
        <>
            <Tooltip title={tooltip} arrow>
                <Button onClick={handleOpen}>{children}</Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => {
                    handleClose(false)
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <p>
                            Title:{' '}
                            <input
                                type='text'
                                value={input}
                                onChange={e => setInput(e.target.value)}
                            />
                        </p>
                        <p>
                            File:{' '}
                            <input type='file' onChange={e => onUpload(e)} />
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleClose(true)
                        }}
                    >
                        OK
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose(false)
                        }}
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
