import { useState } from 'react'
import { SelectModel } from '../../models/selectModel'
import { View } from '../../models/viewModel'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import SelectViewComponent from '../Select/SelectViewComponent'
import { SelectService } from '../../services/SelectService'
import { SelectType } from '../../models/selectModel'
import { ViewService } from '../../services/ViewService'
import { OnAddViewResult } from '../Select/SelectTargetComponent'

interface FileViewProps {
    flowEditor: unknown
    view: View
    viewService: ViewService
    onChangeView: (view: View) => Promise<void>
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
}

const FileView = ({
    flowEditor,
    view,
    viewService,
    onAddView,
}: FileViewProps) => {
    const selectService = new SelectService(view.id ?? '')
    const [selects, setSelects] = useState<SelectModel[]>([])

    const AddSelect = async () => {
        const selectInstance = await selectService.createSelect({
            title: '',
            type: SelectType.Logic,
            view: view.id ?? '',
            response: {},
            target: 'undefined',
        })
        const selectList = [...selects]
        if (selectInstance !== undefined) {
            selectList.push(selectInstance)
        }
        setSelects(selectList)
    }

    const OnDeleteSelect = async (selectId: string | undefined) => {
        if (selectId !== undefined) {
            await selectService.deleteSelect(selectId)
            setSelects((await selectService.getAll()) ?? [])
        }
    }

    const OnAddViewEvent = (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        if (onAddView !== undefined) {
            return onAddView(viewId, title)
        }
        //TODO; Always return something
        return Promise.resolve({
            _views: [],
            id: '',
        })
    }

    return (
        <>
            <TableRow>
                <TableCell>TODO: Fill FileList</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ height: 3 }}>Text</TableCell>
                                <TableCell sx={{ height: 3 }}>
                                    Target
                                    <Button onClick={() => AddSelect()}>
                                        +
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selects &&
                                selects.map(entry => {
                                    return (
                                        <SelectViewComponent
                                            editor={flowEditor}
                                            key={entry.id}
                                            _select={entry}
                                            onDelete={() => {
                                                OnDeleteSelect(entry.id)
                                            }}
                                            onAddView={OnAddViewEvent}
                                            viewService={viewService}
                                            selectService={selectService}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    )
}

export default FileView
