import { List, ListItem } from '@mui/material'
import { Link } from 'react-router-dom' // Ensure you have 'react-router-dom' installed
import SchemaIcon from '@mui/icons-material/Schema'
import KeyIcon from '@mui/icons-material/Key'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import { Settings } from '@mui/icons-material'

const Sidebar = () => {
    return (
        <aside className='sidebar'>
            <nav arial-label='main'>
                <List>
                    <ListItem>
                        <Link to='/edit/flows'>
                            {' '}
                            <SchemaIcon />
                            Flows
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/edit/secrets'>
                            <KeyIcon />
                            Keys
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/edit/media'>
                            <PermMediaIcon />
                            Media
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/edit/companydata'>
                            <Settings />
                            Company Data
                        </Link>
                    </ListItem>
                </List>
            </nav>
        </aside>
    )
}

export default Sidebar
