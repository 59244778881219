import { useCallback, useEffect, useRef, useState } from 'react'
import MDEditor from '@uiw/react-md-editor'

interface TypeTextAreaProps {
    value: string
    _key: string
    onBlur?: (text: string) => void
}

const TypeTextAreaField = ({ value, _key, onBlur }: TypeTextAreaProps) => {
    const [content, setContent] = useState<string | undefined>(value)

    const [isFocused, setIsFocused] = useState(false)
    const editorRef = useRef(null)

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    const OnChangeEvent = useCallback(
        (value: string | undefined) => {
            setContent(value)
        },
        [setContent],
    )

    const OnBlurEvent = useCallback(
        (value: string | undefined) => {
            setContent(value)
            if (onBlur === undefined) {
                return
            }
            onBlur(value ?? '')
        },
        [setContent, onBlur],
    )

    // Detects when the focus state changes
    useEffect(() => {
        if (!isFocused) {
            // The onBlur event logic goes here
            OnBlurEvent(content)
        }
    }, [OnBlurEvent, content, isFocused])

    return (
        <div
            ref={editorRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            tabIndex={0} // Make the div focusable
            data-color-mode='light'
        >
            <div className='wmde-markdown-var'> </div>
            <MDEditor
                key={_key}
                id={_key}
                value={content}
                onChange={OnChangeEvent}
            />
        </div>
    )
    //      <TextArea aria-label="minimum height" minRows={3}  />
    //<img src={isDynamic?"/dynamic.png":"/fix.png"} style={{width:20,height:20}} alt={isDynamic?"dynamic":"fix"} onClick={()=>{setIsDynamic(isDynamic?false:true)}}/>
}

export default TypeTextAreaField
