import { SecretModel } from '../models/secretModel'
import APIService from './APIService'

export class SecretsService {
    public async getAllByUser(
        userId: string,
    ): Promise<SecretModel[] | undefined> {
        return APIService.jsonRequest(`/secrets/${userId}`, 'GET')
    }

    public async addSecret(
        secret: SecretModel,
    ): Promise<SecretModel | undefined> {
        return APIService.jsonRequest(`/secrets`, 'POST', secret)
    }

    public async deleteSecret(secretId: string): Promise<void> {
        return APIService.jsonRequest(`/secrets/${secretId}`, 'DELETE')
    }
}
