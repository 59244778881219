import { useEffect, useMemo, useState } from 'react'
import { LogicSelectType, SelectModel } from '../../models/selectModel'
import { View } from '../../models/viewModel'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { SelectService } from '../../services/SelectService'
import { SelectType } from '../../models/selectModel'
import { ViewService } from '../../services/ViewService'
import { OnAddViewResult } from '../Select/SelectTargetComponent'
import TypeInputField from '../Base/TypeInputField/TypeInputField'
import SelectLogicComponent from '../Select/SelectLogicComponent'

interface LogicViewProps {
    flowEditor: unknown
    view: View
    viewService: ViewService
    onChangeView: (view: View) => Promise<void>
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
}

const LogicView = ({
    flowEditor,
    view,
    viewService,
    onChangeView,
    onAddView,
}: LogicViewProps) => {
    const selectService = useMemo(
        () => new SelectService(view.id ?? ''),
        [view.id],
    )
    const [selects, setSelects] = useState<SelectModel[] | undefined>(undefined)
    const [_view, setView] = useState<View>(view)

    const AddSelect = async () => {
        console.log('Selects currently: ' + JSON.stringify(selects))
        const selectInstance = await selectService.createSelect({
            title: '',
            type: SelectType.Logic,
            view: view.id ?? '',
            response: {
                logicProps: {
                    value: '0',
                    not: false,
                    type: LogicSelectType.Less,
                },
            },
            target: 'undefined',
        })
        const selectList = [...(selects ?? [])]
        if (selectInstance !== undefined) {
            selectList.push(selectInstance)
        }
        setSelects(selectList)
    }

    useEffect(() => {
        const fetchSelects = async () => {
            try {
                const fetchedSelects = await selectService.getAll()
                setSelects(fetchedSelects)
            } catch (error) {
                console.error('Error fetching flows:', error)
                // Handle error appropriately
            }
        }
        if (selects === undefined) {
            fetchSelects()
        }
    }, [selectService, selects]) // Dependency array ensures the effect runs only when userId changes

    const OnDeleteSelect = async (selectId: string | undefined) => {
        if (selectId !== undefined) {
            await selectService.deleteSelect(selectId)
            setSelects(await selectService.getAll())
        }
    }

    const OnAddViewEvent = (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        if (onAddView !== undefined) {
            return onAddView(viewId, title)
        }
        //TODO; Always return something
        return Promise.resolve({
            _views: [],
            id: '',
        })
    }

    const onChangeVariableEvent = async (variable: string) => {
        const updatedView: View = {
            ..._view,
            parameters: {
                logicProps: {
                    sessionValue: variable,
                },
            },
        }
        setView(updatedView)
        await onChangeView(updatedView)
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant='h6'>Variable:</Typography>
                    <TypeInputField
                        text={view?.parameters?.logicProps?.sessionValue}
                        onBlur={onChangeVariableEvent}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ height: 3 }}>
                                    Comparison
                                </TableCell>
                                <TableCell sx={{ height: 3 }}>Value</TableCell>
                                <TableCell sx={{ height: 3 }}>
                                    Target
                                    <Button onClick={() => AddSelect()}>
                                        +
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selects &&
                                selects.map(entry => {
                                    return (
                                        <SelectLogicComponent
                                            editor={flowEditor}
                                            key={entry.id}
                                            _select={entry}
                                            onDelete={() => {
                                                OnDeleteSelect(entry.id)
                                            }}
                                            viewService={viewService}
                                            selectService={selectService}
                                            onAddViewCallback={OnAddViewEvent}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    )
}

export default LogicView
