import { useEffect, useState } from 'react'
import { View } from '../../models/viewModel'
import { SelectService } from '../../services/SelectService'
import { SelectModel } from '../../models/selectModel'
import { ViewService } from '../../services/ViewService'
import SelectionMiniListEntry from './SelectionMiniListEntry'

interface SelectionsMiniListProps {
    view: View
    viewService: ViewService
}

const SelectionsMiniList = ({ view, viewService }: SelectionsMiniListProps) => {
    const [selects, setSelects] = useState<SelectModel[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        const load = async () => {
            const service = new SelectService(view.id ?? '')
            const updatedSelects = await service.getAll()
            setSelects(updatedSelects ?? [])
        }

        if (!loaded) {
            load()
        } else {
            setLoaded(true)
        }
    }, [loaded, view.id])

    return (
        <>
            {selects.map(entry => (
                <SelectionMiniListEntry
                    viewService={viewService}
                    select={entry}
                    key={'selectionMiniListEntry_' + view.id + '_' + entry.id}
                />
            ))}
        </>
    )
}

export default SelectionsMiniList
