import { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectDefaults, SelectModel } from '../../models/selectModel'
import {
    HttpHeaderProps,
    HttpProps,
    ModelDefaults,
    View,
    ViewProps,
} from '../../models/viewModel'
import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { SelectService } from '../../services/SelectService'
import { SelectType } from '../../models/selectModel'
import { ViewService } from '../../services/ViewService'
import TypeInputField from '../Base/TypeInputField/TypeInputField'
import HttpHeaderEntry from '../Base/HttpHeaderEntry'
import { OnAddViewResult } from '../Select/SelectTargetComponent'
import SelectHttpComponent from '../Select/SelectHttpComponent'
import TypeBodyAreaField from '../Base/TypeBodyAreaField'

interface HttpViewProps {
    flowEditor: unknown
    view: View
    viewService: ViewService
    onChangeView: (view: View) => Promise<void>
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
}

const HttpView = ({
    flowEditor,
    view,
    viewService,
    onChangeView,
    onAddView,
}: HttpViewProps) => {
    const selectService = useMemo(
        () => new SelectService(view.id ?? ''),
        [view.id],
    )
    const [selects, setSelects] = useState<SelectModel[]>([])
    const [_view, setView] = useState<View>(view)
    const [_headers, setHeaders] = useState<HttpHeaderProps[]>([
        { name: 'BasicAuth', value: '' },
    ])
    const [viewProps, setViewProps] = useState<ViewProps>(
        view.parameters as ViewProps,
    )
    const [httpProps, setHTTPProps] = useState<HttpProps | undefined>(
        viewProps?.httpProps,
    )
    const [sessionVariable, setSessionVariable] = useState<string>(
        httpProps?.sessionVariable ?? '',
    )

    useEffect(() => {
        if (_view.parameters !== undefined && _view.parameters !== null) {
            // Check if 'headers' exists and is an array
            if (_view?.parameters?.httpProps?.headers) {
                // Assuming HeaderParameter[] is the correct type for _view.parameters.headers
                setHeaders(
                    _view.parameters.httpProps?.headers as HttpHeaderProps[],
                )
                return
            }
        }
    }, [_view.parameters])

    const deepEqual = useCallback(
        (
            obj1: Record<string, unknown> | unknown,
            obj2: Record<string, unknown> | unknown,
        ): boolean => {
            if (obj1 === obj2) return true

            if (
                typeof obj1 !== 'object' ||
                typeof obj2 !== 'object' ||
                obj1 === null ||
                obj2 === null
            ) {
                return false
            }

            const keys1 = Object.keys(obj1 as Record<string, unknown>)
            const keys2 = Object.keys(obj2 as Record<string, unknown>)

            if (keys1.length !== keys2.length) return false

            for (const key of keys1) {
                if (
                    !keys2.includes(key) ||
                    !deepEqual(
                        (obj1 as Record<string, unknown>)[key],
                        (obj2 as Record<string, unknown>)[key],
                    )
                ) {
                    return false
                }
            }

            return true
        },
        [],
    )

    useEffect(() => {
        if (!deepEqual(view.parameters, viewProps)) {
            const load = async () => {
                const loadSelects = await selectService.getAll()
                setSelects(loadSelects ?? [])
            }
            load()

            view.parameters = viewProps
            setView(view)
        }
    }, [deepEqual, selectService, view, viewProps])

    const updateHttpProps = async (httpProps: HttpProps) => {
        setHTTPProps(httpProps)
        const updatedViewProps = { ...viewProps, httpProps: httpProps }
        setViewProps(updatedViewProps)
        const updatedView: View = {
            ..._view,
            parameters: updatedViewProps,
        }
        if (_view.id !== undefined) {
            setView(updatedView)

            await onChangeView(updatedView)
        }
    }

    const OnAddSelect = async () => {
        const selectInstance = await selectService.createSelect({
            title: '',
            type: SelectType.Http,
            view: view.id ?? '',
            response: {
                httpProps: SelectDefaults.DefaultHttpProps,
            },
            target: 'undefined',
        })
        const selectList = [...selects]
        if (selectInstance !== undefined) {
            selectList.push(selectInstance)
        }
        setSelects(selectList)
    }

    const OnDeleteSelect = async (selectId: string | undefined) => {
        if (selectId !== undefined) {
            await selectService.deleteSelect(selectId)
            setSelects((await selectService.getAll()) ?? [])
        }
    }

    const OnChangeURL = async (text: string | undefined) => {
        if (text === undefined || text === null) {
            return
        }
        if (httpProps && text === httpProps.url) {
            return
        }

        const props = {
            ...(httpProps ?? ModelDefaults.defaultHttpProps),
            url: text ?? 'https://google.de',
        }
        updateHttpProps(props)
    }

    const OnSelectMethod = async (event: SelectChangeEvent) => {
        const method = event.target.value ?? 'GET'
        if (httpProps !== undefined && method === httpProps.method) {
            return
        }

        const props = {
            ...(httpProps ?? ModelDefaults.defaultHttpProps),
            method: method,
        }

        updateHttpProps(props)
    }

    const updateHeaders = async (updatedHeaders: HttpHeaderProps[]) => {
        setHeaders(updatedHeaders)

        updateHttpProps({
            ...(httpProps ?? ModelDefaults.defaultHttpProps),
            headers: updatedHeaders,
        })
    }

    const OnAddHeaderEvent = async () => {
        const updatedHeaders: HttpHeaderProps[] = [
            ..._headers,
            {
                name: 'Key',
                value: 'Value',
            },
        ]
        updateHeaders(updatedHeaders)
    }

    const onDeleteHeaderEvent = async (key: string) => {
        const updatedHeaders = _headers.filter(entry => entry.name !== key)
        setHeaders(updatedHeaders)

        if (httpProps !== undefined) {
            updateHttpProps({ ...httpProps, headers: _headers })
        }
    }

    const onChangeHeaderEvent = async (key: string, value: string) => {
        const updateHeaders = _headers.map(entry => {
            if (entry.name === key) {
                entry.value = value
            }
            return entry
        })
        setHeaders(updateHeaders)
    }

    const OnAddViewEvent = (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        if (onAddView !== undefined) {
            return onAddView(viewId, title)
        }
        //TODO; Always return something
        return Promise.resolve({
            _views: [],
            id: '',
        })
    }

    const onUpdateSessionValue = (value: string) => {
        setSessionVariable(value)
        const props = {
            ...(httpProps ?? ModelDefaults.defaultHttpProps),
            sessionVariable: value,
        }

        updateHttpProps(props)
    }

    const updatePostContent = useCallback((text: string): void => {
        const props: HttpProps = {
            ...(httpProps ?? ModelDefaults.defaultHttpProps),
            body: text,
        }
        updateHttpProps(props)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <TableRow>
                <TableCell>
                    URL:
                    <TypeInputField
                        key={'http_' + _view.id}
                        onBlur={OnChangeURL}
                        text={_view.parameters?.httpProps?.url ?? ''}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Method:
                    <Select
                        value={httpProps?.method ?? 'GET'}
                        onChange={OnSelectMethod}
                    >
                        <MenuItem value='GET'>GET</MenuItem>
                        <MenuItem value='POST'>POST</MenuItem>
                        <MenuItem value='PUT'>PUT</MenuItem>
                        <MenuItem value='DELETE'>DELETE</MenuItem>
                    </Select>
                </TableCell>
            </TableRow>
            {(httpProps?.method === 'POST' || httpProps?.method === 'PUT') && (
                <TableRow>
                    <TableCell>
                        <p>Body</p>
                        <TypeBodyAreaField
                            _key={'HTTP_Post_' + view.id}
                            value={httpProps?.body ?? ''}
                            onBlur={updatePostContent}
                        />
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell>
                    <Typography variant='h5'>Headers</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ height: 3, width: '45%' }}>
                                    Key
                                </TableCell>
                                <TableCell sx={{ height: 3, width: '45%' }}>
                                    Value
                                </TableCell>
                                <TableCell sx={{ height: 3, width: '10%' }}>
                                    <Button onClick={() => OnAddHeaderEvent()}>
                                        +
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_headers &&
                                _headers.map((entry, index) => {
                                    return (
                                        <HttpHeaderEntry
                                            key={entry.name + '_' + index}
                                            name={entry.name}
                                            value={entry.value}
                                            onChange={onChangeHeaderEvent}
                                            onDelete={onDeleteHeaderEvent}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant='h6'>
                        Where should the result go?
                    </Typography>
                    <TypeInputField
                        text={sessionVariable}
                        onBlur={onUpdateSessionValue}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant='h5'>ResultRouting</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ height: 3, width: '45%' }}>
                                    Code
                                </TableCell>
                                <TableCell sx={{ height: 3, width: '45%' }}>
                                    Target
                                </TableCell>
                                <TableCell sx={{ height: 3, width: '10%' }}>
                                    <Button onClick={() => OnAddSelect()}>
                                        +
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selects &&
                                selects.map(entry => {
                                    return (
                                        <SelectHttpComponent
                                            editor={flowEditor}
                                            key={entry.id}
                                            _select={entry}
                                            onDelete={() => {
                                                OnDeleteSelect(entry.id)
                                            }}
                                            viewService={viewService}
                                            selectService={selectService}
                                            onAddView={OnAddViewEvent}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    )
}

export default HttpView
