import { Box, TableCell, TableRow } from '@mui/material'
import { ViewService } from '../../services/ViewService'
import { ArrowForward } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { SelectModel, SelectType } from '../../models/selectModel'

interface SelectionMiniListEntryProps {
    viewService: ViewService
    select: SelectModel
}

const SelectionMiniListEntry = ({
    viewService,
    select,
}: SelectionMiniListEntryProps) => {
    const [targetTitle, setTargetTitle] = useState<string>('')
    const [selectTitle, setSelectTitle] = useState<string>('')

    useEffect(() => {
        switch (select.type) {
            case SelectType.Http:
                if (select.response?.httpProps) {
                    setSelectTitle(
                        select.response?.httpProps?.code ?? 'No code',
                    )
                }
                break
            case SelectType.Logic:
                if (select.response?.logicProps) {
                    setSelectTitle(
                        select.response?.logicProps?.type ??
                            '?' + ' ' + select.response?.logicProps?.value ??
                            '',
                    )
                }
                break
            case SelectType.View:
                setSelectTitle(select.title)
                break
        }

        if (select.target !== 'undefined') {
            viewService
                .getViewById(select.target, null, null)
                .then(view => setTargetTitle(view?.title ?? ''))
        } else {
            setTargetTitle('?')
        }
    }, [select, viewService])
    return (
        <TableRow key={'select_mini_' + select.id}>
            <TableCell>
                <Box sx={{ float: 'left' }}>{selectTitle}</Box>
                <Box sx={{ float: 'right' }}>
                    {targetTitle}
                    <ArrowForward />
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default SelectionMiniListEntry
