import { Session } from '../models/sessionModel'
import apiService from './APIService' // Adjust the import path as necessary

export class SessionService {
    public async getAllSessions(): Promise<Session[] | undefined> {
        return apiService.jsonRequest('/sessions', 'GET')
    }

    public async getAllSessionsByFlow(
        flowId: string,
        dataId?: string,
    ): Promise<Session[] | undefined> {
        if (dataId) {
            return apiService.jsonRequest(
                `/sessions/flow/${flowId}?dataId=${dataId}`,
            )
        } else {
            return apiService.jsonRequest(`/sessions/flow/${flowId}`)
        }
    }

    public async getSessionById(id: string): Promise<Session | undefined> {
        return apiService.jsonRequest(`/sessions/${id}`, 'GET')
    }

    public async createSession(
        sessionData: Session,
    ): Promise<Session | undefined> {
        return apiService.jsonRequest('/sessions', 'POST', sessionData)
    }

    public async updateSession(
        id: string,
        sessionData: Partial<Session>,
    ): Promise<Session | undefined> {
        return apiService.jsonRequest(`/sessions`, 'PUT', sessionData)
    }

    public async deleteSession(id: string): Promise<void> {
        return apiService.jsonRequest(`/sessions/${id}`, 'DELETE', null, false)
    }

    public async deleteAllSessionsForFlow(
        flowId: string,
        dataId?: string,
    ): Promise<void> {
        if (dataId) {
            return apiService.jsonRequest(
                `/sessions/flow/${flowId}?dataId=${dataId}`,
                'DELETE',
                null,
                false,
            )
        } else {
            return apiService.jsonRequest(
                `/sessions/flow/${flowId}`,
                'DELETE',
                null,
                false,
            )
        }
    }
}
