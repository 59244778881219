import APIService from './APIService'
import { Data } from '../models/dataModel' // Adjust the import path as necessary
import { Flow } from '../models/flowModel'

export class DataService {
    constructor(private flow: Flow) {}

    public async getDataByFlow(): Promise<Data[] | undefined> {
        try {
            const flowId = this.flow.id
            return await APIService.jsonRequest(`/data/flow/${flowId}`, 'GET')
        } catch (exception) {
            return []
        }
    }

    public async getDataById(id: string): Promise<Data | undefined> {
        return APIService.jsonRequest(`/data/${id}`, 'GET')
    }

    public async createData(data: Data): Promise<Data | undefined> {
        return APIService.jsonRequest('/data', 'POST', data)
    }

    public async updateData(_: string, data: Data): Promise<Data | undefined> {
        return APIService.jsonRequest(`/data`, 'PUT', data)
    }

    public async deleteData(id: string): Promise<void> {
        return APIService.jsonRequest(`/data/${id}`, 'DELETE')
    }
}
