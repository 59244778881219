import APIService from './APIService'
import { Field } from '../models/fieldsModel' // Adjust the import path as necessary
import { Flow } from '../models/flowModel'

export class FieldService {
    constructor(private flow: Flow) {}

    public async getFieldsByFlow(): Promise<Field[] | undefined> {
        try {
            const flowId = this.flow.id ?? ''
            return await APIService.jsonRequest(
                `/fields/flows/${flowId}`,
                'GET',
            )
        } catch (exception) {
            console.error('Error fetching fields by flow ID:', exception)
            return []
        }
    }

    public async getFieldById(id: string): Promise<Field | undefined> {
        try {
            return await APIService.jsonRequest(`/fields/${id}`, 'GET')
        } catch (exception) {
            console.error('Error fetching field by ID:', exception)
            throw exception // Or handle it more gracefully
        }
    }

    public async createField(fieldData: Field): Promise<Field | undefined> {
        try {
            return await APIService.jsonRequest('/fields', 'POST', fieldData)
        } catch (exception) {
            console.error('Error creating field:', exception)
            throw exception // Or handle it more gracefully
        }
    }

    public async updateField(
        id: string,
        fieldData: Field,
    ): Promise<Field | undefined> {
        try {
            fieldData.flow = this.flow.id ?? ''
            return await APIService.jsonRequest(`/fields`, 'PUT', fieldData)
        } catch (exception) {
            console.error('Error updating field:', exception)
            throw exception // Or handle it more gracefully
        }
    }

    public async deleteField(id: string): Promise<void> {
        try {
            await APIService.jsonRequest(`/fields/${id}`, 'DELETE')
        } catch (exception) {
            console.error('Error deleting field:', exception)
            throw exception // Or handle it more gracefully
        }
    }
}
