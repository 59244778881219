import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'

interface StringInputDialogProps {
    isOpen: boolean
    onClose: () => void
    onSubmit: (value: string) => void
    title: string
    text: string
    defaultValue: string
}

export default function StringInputDialog({
    isOpen,
    onClose,
    onSubmit,
    title,
    text,
    defaultValue,
}: StringInputDialogProps) {
    const [inputValue, setInputValue] = useState(defaultValue)
    const [open, setOpen] = useState<boolean>(isOpen)
    const [_text] = useState<string>(text)

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    const handleClose = (okPressed: boolean) => {
        if (okPressed) {
            onSubmit(inputValue)
        }
        onClose() // Close the modal after submitting
        setInputValue('') // Reset the input value
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose(false)
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    <p>{_text}</p>
                    <input
                        type='text'
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        placeholder={defaultValue}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose(true)
                    }}
                >
                    OK
                </Button>
                <Button
                    onClick={() => {
                        handleClose(false)
                    }}
                    autoFocus
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
