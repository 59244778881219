import { Box, IconButton, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import AuthService from '../services/AuthService'
import { isInitializedSelector } from '../state/AuthSlice'
import LogoutIcon from '@mui/icons-material/Logout'

export default function User() {
    const authServiceInstance = AuthService.getInstance()

    const isInitialized = useSelector(isInitializedSelector)

    const onLogout = () => {
        authServiceInstance?.logout() ??
            alert('No AuthService Instance available')
    }

    return !isInitialized || !authServiceInstance?.isLoggedIn ? (
        <Box>
            <Button
                variant='contained'
                color='secondary'
                onClick={authServiceInstance?.login}
                sx={{ mx: 2 }}
            >
                Login
            </Button>
            or
            <Button
                variant='contained'
                color='secondary'
                onClick={authServiceInstance?.register}
                sx={{ mx: 2 }}
            >
                Register
            </Button>
        </Box>
    ) : (
        <Tooltip title='Logout' arrow>
            <IconButton onClick={onLogout}>
                <LogoutIcon sx={{ color: '#fff' }} />
            </IconButton>
        </Tooltip>
    )
}
