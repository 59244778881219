import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import TypeTextAreaField from '../Base/TypeTextAreaField/TypeTextAreaField'
import SelectComponent from '../Select/SelectViewComponent'
import { UIInputField, UIInputType, View } from '../../models/viewModel'
import { SelectModel } from '../../models/selectModel'
import { useEffect, useMemo, useState } from 'react'
import { SelectService } from '../../services/SelectService'
import { SelectType } from '../../models/selectModel'
import { ViewService } from '../../services/ViewService'
import { OnAddViewResult } from '../Select/SelectTargetComponent'
import InputSelect from '../InputSelect/InputSelect'
import { GridAddIcon } from '@mui/x-data-grid'

interface DescriptionViewProps {
    view: View
    viewService: ViewService
    flowEditor: unknown
    onChangeView: (view: View) => Promise<void>
    onAddView: (viewId: string, title: string) => Promise<OnAddViewResult>
}

const DescriptionView = ({
    view,
    flowEditor,
    viewService,
    onChangeView,
    onAddView,
}: DescriptionViewProps) => {
    const selectService = useMemo(
        () => new SelectService(view.id ?? ''),
        [view.id],
    )
    const [selects, setSelects] = useState<SelectModel[] | undefined>(undefined)
    const [_view, setView] = useState<View>(view)
    const [inputs, setInputs] = useState<UIInputField[]>([])

    const OnBlurDescription = async (text: string) => {
        if (text !== _view.description) {
            const updatedView: View = {
                ..._view,
                description: text,
            }
            if (_view.id !== undefined) {
                setView(updatedView)
                await onChangeView(updatedView)
            }
        }
    }

    const AddSelect = async () => {
        await selectService.createSelect({
            title: '',
            type: SelectType.View,
            view: _view.id ?? '',
            response: {},
            target: 'undefined',
        })
        setSelects(await selectService.getAll())
    }

    const onAddInputEvent = async () => {
        const updatedInputs: UIInputField[] = [
            ...inputs,
            {
                name: 'Name',
                type: UIInputType.Text,
                variable: 'Variable',
                index: inputs.length,
            },
        ]
        setInputs(updatedInputs)
        const updatedView: View = {
            ...view,
            parameters: {
                descriptionProps: {
                    inputs: updatedInputs,
                },
            },
        }
        setView(updatedView)
        await onChangeView(updatedView)
    }

    const onChangeInputEvent = async (index: number, input: UIInputField) => {
        const updatedView: View = {
            ...view,
        }
        if (
            updatedView &&
            updatedView.parameters &&
            updatedView.parameters.descriptionProps
        ) {
            updatedView.parameters.descriptionProps.inputs[index] = input
            await onChangeView(updatedView)
        }
    }

    const onDeleteInputEvent = async (index: number) => {
        const updatedView: View = {
            ...view,
        }
        if (
            updatedView.parameters &&
            updatedView.parameters.descriptionProps &&
            updatedView.parameters.descriptionProps.inputs
        ) {
            updatedView.parameters.descriptionProps.inputs =
                view?.parameters?.descriptionProps?.inputs.filter(
                    entry => entry.index !== index,
                ) || []
        }
        setView(updatedView)
        await onChangeView(updatedView)
    }

    useEffect(() => {
        setView(view)
    }, [view])

    useEffect(() => {
        const fetchSelects = async () => {
            try {
                const fetchedSelects = await selectService.getAll()
                setSelects(fetchedSelects)
                await new Promise(resolve => setTimeout(resolve, 4000))
            } catch (error) {
                console.error('Error fetching flows:', error)
                // Handle error appropriately
            }
        }
        if (selects === undefined) {
            fetchSelects()
        }

        if (_view && _view.parameters && _view.parameters.descriptionProps) {
            setInputs(_view.parameters.descriptionProps.inputs)
        }
    }, [_view, selectService, selects]) // Dependency array ensures the effect runs only when userId changes

    const OnDeleteSelect = async (selectId: string | undefined) => {
        if (selectId !== undefined) {
            await selectService.deleteSelect(selectId)
            setSelects(await selectService.getAll())
        }
    }

    const OnAddViewEvent = (
        viewId: string,
        title: string,
    ): Promise<OnAddViewResult> => {
        if (onAddView !== undefined) {
            return onAddView(viewId, title)
        }
        //TODO; Always return something
        return Promise.resolve({
            _views: [],
            id: '',
        })
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography>Description</Typography>
                    <TypeTextAreaField
                        _key={'Description_' + _view.id}
                        value={_view.description ?? ''}
                        onBlur={OnBlurDescription}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '35%' }}>
                                    Title
                                </TableCell>
                                <TableCell sx={{ width: '35%' }}>
                                    Variable Name
                                </TableCell>
                                <TableCell sx={{ width: '20%' }}>
                                    Type
                                </TableCell>
                                <TableCell sx={{ width: '10%' }}>
                                    <Button onClick={onAddInputEvent}>
                                        <GridAddIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inputs &&
                                inputs.map((entry, index) => {
                                    return (
                                        <InputSelect
                                            key={'Input_Select_' + index}
                                            view={view}
                                            index={index}
                                            input={entry}
                                            onChangeInput={onChangeInputEvent}
                                            onDeleteInput={onDeleteInputEvent}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography variant='h5'>Selections</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ height: 3, width: '40%' }}>
                                    Text
                                </TableCell>
                                <TableCell sx={{ height: 3, width: '40%' }}>
                                    Target
                                </TableCell>
                                <TableCell sx={{ height: 3, width: '20%' }}>
                                    <Button onClick={() => AddSelect()}>
                                        +
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selects &&
                                selects.map(entry => {
                                    return (
                                        <SelectComponent
                                            editor={flowEditor}
                                            key={entry.id}
                                            _select={entry}
                                            onDelete={() => {
                                                OnDeleteSelect(entry.id)
                                            }}
                                            onAddView={OnAddViewEvent}
                                            viewService={viewService}
                                            selectService={selectService}
                                        />
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    )
}

export default DescriptionView
