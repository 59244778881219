import { Link, Stack, styled, Typography } from '@mui/material'
import { FlowService } from '../../services/FlowService'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Imprint } from '../../models/imprint'

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    font-family: Roboto;
    font-size: 0.9rem;
    width: fit-content;

    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
            color: #0d5f99;
        }
    }
`

const StyledTypography = styled(Typography)`
    font-size: 0.9rem;
    width: fit-content;
`

export const ImprintComponent = ({ flowId }: { flowId?: string }) => {
    const flowService = useMemo(() => new FlowService(), [])
    const [imprint, setImprint] = useState<Imprint>()

    const getImprint = useCallback(async () => {
        if (flowId) {
            const res = await flowService.getFlowImprint(flowId)
            setImprint(res)
        }
    }, [flowId, flowService])

    useEffect(() => {
        getImprint()
    }, [getImprint])

    return flowId ? (
        <Stack gap='5px'>
            <StyledTypography>{imprint?.addressData?.name}</StyledTypography>
            <StyledTypography>
                {imprint?.addressData?.country}, {imprint?.addressData?.city},{' '}
                {imprint?.addressData?.street}, {imprint?.addressData?.zipCode}
            </StyledTypography>
            <StyledTypography>
                {imprint?.contactData?.mainPhoneNumber}
            </StyledTypography>
            <StyledLink href={`mailto:${imprint?.contactData?.email}`}>
                {imprint?.contactData?.email}
            </StyledLink>
            {imprint?.privacyURL && (
                <StyledLink
                    href={imprint?.privacyURL}
                    target='_blank'
                    rel='noreferrer'
                >
                    Privacy and Policy
                </StyledLink>
            )}
            <StyledLink
                href={imprint?.domainURL}
                target='_blank'
                rel='noreferrer'
            >
                {imprint?.domainURL}
            </StyledLink>
        </Stack>
    ) : null
}
