import { Autocomplete, TextField } from '@mui/material'
import React, { useState } from 'react'

type AutocompleteProps<T = unknown> = {
    options: T[]
    value: T | null
    setValue: React.Dispatch<React.SetStateAction<T | null>>
    loading: boolean
    label: keyof T
}

export const AutoComplete = <T,>({
    options,
    value,
    loading,
    setValue,
    label,
}: AutocompleteProps<T>) => {
    const [originInput, setOriginInput] = useState('')

    const onChangeInput = (_: React.SyntheticEvent, value: string) => {
        setOriginInput(value)
    }

    const onChange = (_: React.SyntheticEvent, value: T | null) => {
        setValue(value)
    }

    return (
        <Autocomplete
            autoComplete
            filterOptions={() => options || []}
            options={options}
            getOptionLabel={(option: T) => String(option[label])}
            filterSelectedOptions
            autoSelect
            autoHighlight
            loading={loading}
            onChange={onChange}
            onInputChange={onChangeInput}
            renderInput={params => (
                <TextField
                    {...params}
                    label='Font'
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
            inputValue={originInput}
            value={value}
        />
    )
}
